import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IAdminDetailedInquiry, InquiryStatus } from 'projects/webapp/app/interfaces/inquiry.interface';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { formatEnumKey } from 'projects/webapp/app/utils';


@Component({
  selector: 'app-admin-inquiries',
  templateUrl: './admin-inquiries.component.html',
  styleUrls: ['./admin-inquiries.component.scss']
})
export class AdminInquiriesComponent implements OnInit {

  statuses = Object.entries(InquiryStatus)
    .filter(([key, value]) => typeof value === 'number') // Filter only numeric values
    .map(([key, value]) => ({ value: value as number, viewValue: formatEnumKey(key) }));

  selectedStatuses: number[] = this.statuses
    .filter(status => ![InquiryStatus.ARCHIVED, InquiryStatus.UNKNOWN, InquiryStatus.DELETED].includes(status.value))
    .map(status => status.value);

  public isLoading = false;
  items: IAdminDetailedInquiry[] = []

  displayedColumns: string[] = ['id', 'name', 'status', 'from', 'to', 'offers', 'details'];
  dataSource = new MatTableDataSource<any>([]);
  totalItems = 0;
  pageSize = 10;
  page = 1;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private title: Title,
    private router: Router,
    private adminService: AdminService,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Admin Anfragen`);
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.adminService.getInquiries({
        page: this.page,
        page_size: this.pageSize,
        status: this.selectedStatuses
      }))
      // this.items = response.results
      this.dataSource.data = response.results; // Adjust based on your API response
      this.totalItems = response.count; // Assuming 'count' is the total items from the API
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  onClickDetails(uuid: string) {
    this.router.navigate(['/main/admin/inquiries/' + uuid])
  }

  // fetchData(page: number, pageSize: number): void {
  //   const apiUrl = `http://localhost:8000/api/your-endpoint/?page=${page}&page_size=${pageSize}`;
  //   this.http.get<any>(apiUrl).subscribe((data) => {
  //     this.dataSource.data = data.results; // Adjust based on your API response
  //     this.totalItems = data.count; // Assuming 'count' is the total items from the API
  //   });
  // }

  onPaginateChange(event: any): void {
    // this.fetchData(event.pageIndex + 1, event.pageSize);
    this.page = event.pageIndex + 1
    this.pageSize = event.pageSize
    this.refresh()
    // this.page = this.page + 1
  }

  onStatusFilterChange(): void {
    this.refresh()
    // Reset to the first page when applying a new filter
    // this.fetchData(1, this.pageSize, this.selectedStatuses);
  }

}
