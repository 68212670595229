import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { IUser, UsersService } from 'projects/webapp/app/services/users.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  ownUser: IUser | null = null;


  form = new FormGroup({
    username: new FormControl(''),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    email: new FormControl(''),
    phone: new FormControl('', Validators.required),
  });


  constructor(
    public authService: AuthService,
    public usersService: UsersService,
    public dialog: MatDialog,
    private title: Title,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService
  ) {
    this.title.setTitle('Movline24: Mein Profil')
  }

  async ngOnInit() {
    await this.refresh()
  }

  async refresh() {
    this.spinnerService.show()
    this.ownUser = await this.usersService.getOwnUser().toPromise() as any;
    if (this.ownUser){

      if (!!this.ownUser.avatardocument_object && this.ownUser.avatardocument_object.file){
        this.ownUser.avatardocument = this.ownUser.avatardocument_object.file;
      }
      this.form.patchValue(this.ownUser);
    }

    // this.tokens = await lastValueFrom(this.usersService.getUserPushTokens())

    this.spinnerService.hide()
  }

  async submit() {
    const response = await this.usersService.updateOwnUser(this.form.value).toPromise()
    console.log(response);
    this.toastrService.success('Profil aktualisiert!')
    await this.refresh()
  }

}
