import { FormGroup } from "@angular/forms";
import { IDetailedInquiry, InquiryServiceType } from "./interfaces/inquiry.interface";
import { IDetailedOffer } from "./interfaces/offer.interface";

export const cleanObject = (obj: any) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
}

export const deepCopy = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
}

export const hasMoveType = (serviceType: any) => {
  return serviceType == InquiryServiceType.MOVE ||
    serviceType == InquiryServiceType.MOVE_AND_CLEAN ||
    serviceType == InquiryServiceType.PIANO_MOVE ||
    serviceType == InquiryServiceType.OFFICE_MOVE
}


export const hasMoveCleaning = (serviceType: any) => {
  return serviceType == InquiryServiceType.MOVE_AND_CLEAN ||
    serviceType == InquiryServiceType.CLEAN
}

export const calculateTotalPrice = (inquiry: IDetailedInquiry, offer: IDetailedOffer) => {
  let total_price = 0;
  if (offer.price_move && hasMoveType(inquiry.service)) {
    total_price += parseInt(offer.price_move)
  }
  if (offer.price_montage && inquiry.services_assembling) {
    total_price += parseInt(offer.price_montage)
  }
  if (offer.price_cleaning && hasMoveCleaning(inquiry.service)) {
    total_price += parseInt(offer.price_cleaning)
  }
  if (offer.price_disposal && inquiry.service_trash_per_cubic) {
    total_price += parseInt(offer.price_disposal)
  }
  if (offer.price_service_mount_lamps) {
    total_price += parseInt(offer.price_service_mount_lamps)
  }
  if (offer.price_service_floorliner) {
    total_price += parseInt(offer.price_service_floorliner)
  }
  if (offer.price_service_boxes) {
    total_price += parseInt(offer.price_service_boxes)
  }
  if (offer.price_services_clothes_boxes) {
    total_price += parseInt(offer.price_services_clothes_boxes)
  }
  if (offer.price_services_furniture_lift) {
    total_price += parseInt(offer.price_services_furniture_lift)
  }
  console.log(total_price)
  return total_price
}

export const formatEnumKey = (key: string): string => {
  return key
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const outputErrors = (form: FormGroup) => {
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    if (control && control.invalid) {
      let message = '';
      if (control && control.errors && control.errors['required']) {
        message += `${field} is required. `;
      }
      console.log(message);
    }
  });
}


export const replaceURLs = (message: string) => {
  if (!message) { return; }

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.replace(urlRegex, (url) => {
    let hyperlink = url;
    if (!hyperlink.match('^https?:\/\/')) {
      hyperlink = 'http://' + hyperlink;
    }
    return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
  });
};


export const getUniqueObjects = <T>(array: T[]): T[] => {
  const uniqueObjects = new Set();
  const uniqueArray: T[] = [];

  array.forEach(obj => {
    const objString = JSON.stringify(obj);

    if (!uniqueObjects.has(objString)) {
      uniqueObjects.add(objString); // track unique stringified objects
      uniqueArray.push(obj); // add the original object to the array
    }
  });

  return uniqueArray;
}


export const downloadFile = (blob: Blob, filename: string) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const parseFilename = (contentDisposition: string): string | null => {
  if (!contentDisposition) { return null; }
  const fileNameRaw = contentDisposition.split('filename=')[1];
  return fileNameRaw.replace(/"/g, '');
}

export const openInGoogleMaps = (address: string, zip_code: string | number, location: string) => {
  const url = 'http://maps.google.com/?q=' + `${address}, ${zip_code} ${location}`;
  window.open(url);
}

export const sleep = (m: number) => new Promise(r => setTimeout(r, m))

export interface PaginatedResponse<T> {
  results: T[];
  "count": number,
  "next": any,
  "previous": any,
}
