<div class="container-fluid">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1>Alle Bewertungen</h1>
      </div>

      <div *ngIf="!isLoading && items.length == 0 ">
        <div class="alert alert-info mt-5">Keine Bewertungen</div>
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
      </div>

      <table *ngIf="!!items && items.length >= 1" style="width: 100%" class="table table-striped">
        <thead>
          <td>Id</td>
          <td>Datum</td>
          <td>Author</td>
          <td>Firma</td>
          <td>Auftrag</td>
          <td>Bewertung</td>
          <td>Beschreibung</td>
          <td>Sichtbar</td>
          <td></td>
        </thead>

        <tbody>
          <tr *ngFor="let item of items">
            <td># {{item.id}}</td>
            <td>{{item.updated_at | humanDatePipe}}</td>
            <td>{{item.author}}</td>

            <td>
              <a *ngIf="item?.organization_object?.name"
                routerLink="/main/admin/organizations/{{item?.organization_object?.uuid}}">{{item?.organization_object?.name}}</a>
              <br><br>
              <a *ngIf="item?.organization_object?.name" target="_blank"
                href="{{environment.uiDomain}}/company-rating?tk={{item?.organization_object?.uuid}}">Rating Page</a>
            </td>

            <td>
              <a *ngIf="item?.order_object"
                routerLink="/main/admin/orders/{{item?.order_object?.uuid}}">Auftrag</a>
            </td>
            <td> {{item.rating | number : '1.2-2' }} </td>
            <td>{{item.text }}</td>
            <td>
              <mat-slide-toggle [(ngModel)]="item.is_active" (change)="onChange(item.uuid, $event)"></mat-slide-toggle>
            </td>
          </tr>
        </tbody>
      </table>

      <pre *ngIf="environment.envName == 'local_dev'">{{items | json}}</pre>

    </mat-card-content>
  </mat-card>
</div>
