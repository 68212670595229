<ngx-spinner [name]="'order-details'" [fullScreen]="false"></ngx-spinner>
<div *ngIf="item && item.offer_object">

  <div class="mt-2 mb-3 text-center" *ngIf="authService.isAuthenticatedPlatformAdmin()">
    <a mat-flat-button color="accent" routerLink="/main/admin/orders/{{item.uuid}}">ADMIN PAGE</a>
  </div>

  <div class="container mt-2">
    <div style="display: flex; flex-direction: row; justify-content: space-between; ">
      <h1>
        <span *ngIf="!!item?.offer_object?.inquiry_object">
          Direkter
        </span>
        Auftrag Id "{{item.id}}"
      </h1>
      <div class="co-buttons-list">
        <button mat-flat-button color="primary" (click)="onClickDone()"
          *ngIf="item?.status==movLineOrderStateType.OPEN">
          Als erledigt markieren
        </button>
      </div>
    </div>
    <div class="alert alert-info">
      Status: {{item.status | orderStatePipe}}
    </div>
  </div>

  <app-inquiry-details-card [item]="item.offer_object.inquiry_object"></app-inquiry-details-card>

  <div class="container">
    <hr>
    <h2>Kontaktdetails</h2>
    <div>
      <mat-card class="mt-3">
        <mat-card-content>

          <ul class="co-simple-list">
            <li>E-Mail: {{item.offer_object.inquiry_object.email}}</li>
            <li>Anrede: {{item.offer_object.inquiry_object.gender}}</li>
            <li>Vorname: {{item.offer_object.inquiry_object.first_name}}</li>
            <li>Nachname: {{item.offer_object.inquiry_object.last_name}}</li>
            <li>Telefon: {{item.offer_object.inquiry_object.phone || "-"}}</li>
          </ul>

        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="container mt-5">
    <h2>Meine Kommentare als Firma (für mich)</h2>
    <div>
      <mat-card class="mt-3">
        <mat-card-content>

          <comments-card-component [adminMode]="false" [entityUUID]="this.orderUUID"></comments-card-component>

          <pre *ngIf="environment.envName == 'local_dev'">{{item | json}}</pre>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
