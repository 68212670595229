<body class="body">
  <section class="header">
    <div class="div-block-4">
      <img src="/assets/logos/movline-logo-white.png" loading="lazy" width="207" alt="">
    </div>
  </section>
  <div class="step-1">
    <h1 class="heading">Vielen Dank für Ihre Anfrage</h1>
    <p class="paragraph">Wir suchen für Sie die besten Partnerfirmen</p>


    <div class="container next-steps">

      <div class="row mx-1">

        <div class="col-sm-12 col-md-5 movline-box">
          <div>

            <h4 class="q_title">Mit Ihren weiteren Antworten erhalten Sie schneller ein Angebot.</h4>
            <div style="display: flex; justify-content: center;">
              <img src="/assets/task-list_18158202.png" width="50%" />
            </div>
            <p>Für eine präzise Offerte benötigen wir Informationen zur Hausgrösse, Reinigungsmenge und Erreichbarkeit.
              Detaillierte
              Antworten helfen uns, Ihre Bedürfnisse besser zu verstehen und schneller ein Angebot zu erstellen.</p>
          </div>
          <button (click)="onClickMoreQuestions()" class="submit-button w-button w-100 mt-4"
            [disabled]="isLoading">Fragen
            beantworten</button>

        </div>

        <div class="col-sm-12 col-md-2 or-box">
          <span class="border-txt">ODER</span>
        </div>

        <div class=" col-sm-12 col-md-5 movline-box">
          <div>
            <h4 class="">Kostenlose Besichtigung anfordern um weitere details zu klären</h4>
            <div style="display: flex; justify-content: center;">
              <img src="/assets/places-visit_4052301.png" width="50%" />
            </div>
            <p>Wir setzen uns mit Ihnen in
              Verbindung, um alle Details mit Ihnen durchzugehen. Dieser Service ist für Sie
              kostenlos.</p>
          </div>
          <button (click)="onClickRequestVisit()" class="submit-button w-button w-100 mt-4"
            [disabled]="isLoading">Besichtigung anfragen</button>
        </div>
      </div>
    </div>
  </div>
</body>
