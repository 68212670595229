<body class="body">
  <!-- <app-inquiry-header [step]="2"></app-inquiry-header> -->
  <app-inquiry-header [step]="1" [withProgressbar]="false"></app-inquiry-header>

  <div class="step-1">
    <h1 class="heading">Anfrage ergänzen</h1>
    <!-- <p class="paragraph">Finden Sie die besten Angebote von Firmen aus Ihrer Region</p> -->
    <div class="step-1-wrapper">
      <div class="w-form">

        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form"
          data-wf-page-id="65ccc93e5af9b9c09bb3b38c" data-wf-element-id="3c22ea9e-8587-6cdb-19ca-53e38eea0fb5"
          [formGroup]="draftInquiryForm" *ngIf="!!draftInquiryForm && draftInquiryForm.value.service == inquiryServiceType.MOVE_AND_CLEAN || draftInquiryForm.value.service == inquiryServiceType.CLEAN">
          <div class="main-wrapper">
            <label for="Service-w-hlen" class="field-label box edit">Was soll gereinigt
              werden</label>
            <div id="w-node-f4c45083-6081-de4a-0ed5-caf2a4acb777-9bb3b38c" class="w-layout-layout quick-stack wf-layout-layout">
              <div id="w-node-f4c45083-6081-de4a-0ed5-caf2a4acb778-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio" id="radio" name="radio" formControlName="what_to_clean_all_rooms"
                    class="w-form-formradioinput w-radio-input" value="Radio"><span class="w-form-label" for="radio">Alle
                    Zimmer</span>
                </label>
              </div>
              <div id="w-node-f4c45083-6081-de4a-0ed5-caf2a4acb779-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 6" id="radio-6" name="radio"
                    formControlName="what_to_clean_kitchen_toilet" class="w-form-formradioinput w-radio-input"
                    value="Radio"><span class="w-form-label" for="radio-6">Küche, Bad &amp; WC</span>
                </label>
              </div>
              <div id="w-node-_50ce9b6c-b8e9-f066-30d4-6af56ee99613-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 3" id="radio-3" name="radio" formControlName="what_to_clean_attic"
                    class="w-form-formradioinput w-radio-input" value="Radio"><span class="w-form-label"
                    for="radio-3">Dachboden</span>
                </label>
              </div>
              <div id="w-node-f20fa909-09f6-f3dd-8310-83eea2109b04-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 4" id="radio-4" name="radio" formControlName="what_to_clean_balcony"
                    class="w-form-formradioinput w-radio-input" value="Radio"><span class="w-form-label"
                    for="radio-4">Balkon</span>
                </label>
              </div>
              <div id="w-node-c060afe0-f12a-b450-d9fc-b42f5e7b19c2-9bb3b38c" class="w-layout-cell">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 5" id="radio-5" name="radio"
                    formControlName="what_to_clean_winter_garden" class="w-form-formradioinput w-radio-input"
                    value="Radio"><span class="w-form-label" for="radio-5">Wintergarten</span>
                </label>
              </div>
              <div id="w-node-b76ba67d-a5b1-f3b2-bdea-a9c141743e4d-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 7" id="radio-7" name="radio" formControlName="what_to_clean_basement"
                    class="w-form-formradioinput w-radio-input" value="Radio"><span class="w-form-label"
                    for="radio-7">Keller/Kellerabteil</span>
                </label>
              </div>
            </div>
            <label for="Service-w-hlen" class="field-label box edit">Welche Besonderheiten gelten für Ihre
              Unterkunft</label>
            <div id="w-node-_3e3f0258-77df-4ead-b2c1-91c540945b5c-9bb3b38c"
              class="w-layout-layout quick-stack wf-layout-layout">
              <div id="w-node-_3e3f0258-77df-4ead-b2c1-91c540945b5d-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 8" id="radio-8" name="checkbox"
                    formControlName="special_clean_build_in_wardrobes" class="w-form-formradioinput w-radio-input"
                    value="Radio"><span class="w-form-label" for="radio-8">Einbauschranke (exkl. Küche)</span></label>
              </div>
              <div id="w-node-_3e3f0258-77df-4ead-b2c1-91c540945b62-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 8" id="radio-8" name="checkbox"
                    formControlName="special_clean_hardened_dirt" class="w-form-formradioinput w-radio-input"
                    value="Radio"><span class="w-form-label" for="radio-8">Stark verhärteter Dreck</span></label>
              </div>
              <div id="w-node-_3e3f0258-77df-4ead-b2c1-91c540945b67-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 8" id="radio-8" name="checkbox" formControlName="special_clean_mold"
                    class="w-form-formradioinput w-radio-input" value="Radio"><span class="w-form-label" for="radio-8">Schimmel
                    an den Fensterrahmen</span></label>
              </div>
              <div id="w-node-_3e3f0258-77df-4ead-b2c1-91c540945b6c-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 8" id="radio-8" name="checkbox"
                    formControlName="special_clean_has_stuff" class="w-form-formradioinput w-radio-input" value="Radio"><span
                    class="w-form-label" for="radio-8">Zum Zeitpunkt der Reinigung
                    möbliert</span></label>
              </div>
              <div id="w-node-_3e3f0258-77df-4ead-b2c1-91c540945b71-9bb3b38c" class="w-layout-cell">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 8" id="radio-8" name="checkbox"
                    formControlName="special_clean_has_washing_machine" class="w-form-formradioinput w-radio-input"
                    value="Radio"><span class="w-form-label" for="radio-8">Waschturm</span></label>
              </div>
              <div id="w-node-_3e3f0258-77df-4ead-b2c1-91c540945b76-9bb3b38c" class="w-layout-cell cell-3">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 8" id="radio-8" name="checkbox"
                    formControlName="special_clean_has_pets" class="w-form-formradioinput w-radio-input" value="Radio"><span
                    class="w-form-label" for="radio-8">Haustierhaltung</span></label>
              </div>

              <div id="w-node-_4e1b7e39-b00f-e3f2-7344-15182015d873-9bb3b38c" class="w-layout-cell">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 9" id="radio-9" name="checkbox"
                    formControlName="special_clean_has_cheminee" class="w-form-formradioinput w-radio-input" value="Radio"><span
                    class="w-form-label" for="radio-9">Reinigung des Cheminées</span></label>
              </div>
              <div id="w-node-_32b75c26-5279-2921-d4fb-3efd0de7e844-9bb3b38c" class="w-layout-cell">
                <label class="w-radio">
                  <input type="checkbox" data-name="Radio 9" id="radio-9" name="checkbox"
                    formControlName="special_clean_shampoo_carpet" class="w-form-formradioinput w-radio-input"
                    value="Radio"><span class="w-form-label" for="radio-9">Teppich shampoonieren</span></label>
              </div>
              <div id="w-node-_39efb119-d51b-b787-8374-8b404a80e807-9bb3b38c" class="w-layout-cell cell-5">
                <input class="text-field-4 w-input" maxlength="256" name="field-3" data-name="Field 3"
                  placeholder="Anzahl Teppiche" type="text" id="field-3" formControlName="special_clean_shampoo_carpet_number">
              </div>
            </div>
            <div class="haus">
              <label for="Service-w-hlen-2" class="field-label box edit _20pxtop">Wie viele Badezimmer
                sollen gereinigt werden?</label>

              <app-selector [control]="$any(draftInquiryForm).controls['number_of_showers']"
                [fieldName]="selectors.number_of_showers.fieldName" [optionsList]="selectors.number_of_showers.options">
              </app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['number_of_toilets']"
                [fieldName]="selectors.number_of_toilets.fieldName" [optionsList]="selectors.number_of_toilets.options">
              </app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['number_of_sinks']"
                [fieldName]="selectors.number_of_sinks.fieldName" [optionsList]="selectors.number_of_sinks.options">
              </app-selector>

            </div>
            <div class="haus">
              <label for="Service-w-hlen" class="field-label box edit _20pxtop">Wie viele Fenster sollen
                gereinigt werden</label>

              <app-selector [control]="$any(draftInquiryForm).controls['window_normal']"
                [fieldName]="selectors.window_normal.fieldName" [optionsList]="selectors.window_normal.options">
              </app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['window_wall']"
                [fieldName]="selectors.window_wall.fieldName" [optionsList]="selectors.window_wall.options">
              </app-selector>

              <app-selector [control]="$any(draftInquiryForm).controls['window_doors']"
                [fieldName]="selectors.window_doors.fieldName" [optionsList]="selectors.window_doors.options">
              </app-selector>

            </div>
          </div>
          <input (click)="onSubmit()" type="submit" data-wait="bitte warten" class="submit-button w-button" value="WEITER"
            style="margin-bottom: 20px;">
          <a class="movline-back-button w-button" [routerLink]="['..']" style="text-align: center;">ZURÜCK</a>
        </form>


        <pre *ngIf="environment.envName == 'local_dev'">{{draftInquiryForm.value | json}}</pre>

      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <p>Build {{versions.hash}} von {{versions.date | date: 'yyyy-MM-dd HH:mm'}}</p>
  </div>

</body>
