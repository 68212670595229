import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-inquiry-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.scss']
})
export class InquiryNextStepsComponent implements OnInit {

  isLoading = true;

  currentInquiryUUID = ''


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private publicInquiryService: PublicInquiryService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.isLoading = false;

      const currentInquiryUUID = params['currentInquiryUUID']
      console.log(currentInquiryUUID)
      this.currentInquiryUUID = currentInquiryUUID;
      if (!currentInquiryUUID) {
        this.router.navigate(['inquiry'])
      }
    });
  }

  onClickMoreQuestions() {
    this.router.navigate(['/inquiry/2'], {
      queryParams: { currentInquiryUUID: this.currentInquiryUUID },
    });
  }

  async onClickRequestVisit() {
    try {
      this.isLoading = true;
      const response = await lastValueFrom(this.publicInquiryService.requestVisit(this.currentInquiryUUID, ''))
      console.log(response)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.router.navigate(['inquiry/success'])
    }
  }
}
