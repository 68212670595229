
export const NUMBER_OF_ROOMS_MAPPING_LIST = [
  { 'value': "0", 'display': 'k.A.' },
  { 'value': "1", 'display': '1' },
  { 'value': "2", 'display': '1.5' },
  { 'value': "3", 'display': '2' },
  { 'value': "4", 'display': '2.5' },
  { 'value': "5", 'display': '3' },
  { 'value': "6", 'display': '3.5' },
  { 'value': "7", 'display': '4' },
  { 'value': "8", 'display': '4.5' },
  { 'value': "9", 'display': '5' },
  { 'value': "10", 'display': '5.5' },

  { 'value': "11", 'display': '6' },
  { 'value': "12", 'display': '6.5' },
  { 'value': "13", 'display': '7' },
  { 'value': "14", 'display': '7.5' },
  { 'value': "15", 'display': '8+' },
]

export const FROM_SIZE_SQUARE_METERS = [
  { "value": "0", display: "k.A." },
  { "value": "1", display: "50m2" },
  { "value": "2", display: "70m2" },
  { "value": "3", display: "90m2" },
  { "value": "4", display: "110m2" },
  { "value": "5", display: "130m2" },
  { "value": "6", display: "150m2" },
  { "value": "7", display: "170m2" },
  { "value": "8", display: "190m2" },
  { "value": "9", display: "210m2" },
  { "value": "10", display: "+210m2" },
]

export const SIZE_FLOORS =
[
  { "value": "0", display: "k.A." },
  { "value": "1", display: "1" },
  { "value": "2", display: "2" },
  { "value": "3", display: "3" },
  { "value": "4", display: "4" },
  { "value": "5", display: "5" },
  { "value": "6", display: "6" },
  { "value": "7", display: "7" },
  { "value": "8", display: "8" },
  { "value": "9", display: "9" },
  { "value": "10", display: "+10" },
]

export const LIFT_SIZE = [
  { "value": "0", display: "k.A." },
  { "value": "1", display: "Kein Lift" },
  { "value": "2", display: "Ja, für 2-3 Personen" },
  { "value": "3", display: "Ja, fur 4-5 Personen" },
  { "value": "4", display: "Ja, für 6+ Personen" },
  { "value": "5", display: "Ja, Warenlift für 10+ Personen" },
]
