<h1 mat-dialog-title>
  <span>Details der Offerte</span>
</h1>
<div mat-dialog-content>

  <p>Offeriert von {{data.offer.organization_object.name}}</p>

  <form [formGroup]="form">
    <div id="w-node-d6805926-e433-82cc-79e3-a3863a9b23aa-b71d2c8e" class="w-layout-cell">
      <div style="width: 100%;">

        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_move > 0 && (hasMoveType(data.inquiry.service))">
          <span>
            <input type="checkbox" class="movline-checkbox" formControlName="price_move">Umzugspreis:
          </span>
          <span>{{data.offer.price_move | thousandsSeparator}}</span>
        </span>

        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_cleaning > 0 && (hasMoveCleaning(data.inquiry.service))">
          <span>
            <input type="checkbox" class="movline-checkbox" formControlName="price_cleaning">Reinigung:
          </span>
          <span>{{data.offer.price_cleaning | thousandsSeparator}}</span>
        </span>

        <!-- <pre>{{data.offer.price_montage}}</pre>
                            <pre>{{data.inquiry.services_assembling}}</pre> -->
        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_montage > 0 &&  data.inquiry.services_assembling">
          <span>
            <input type="checkbox" class="movline-checkbox" formControlName="price_montage">Ab- und
            Aufbau:
          </span>
          <span>{{data.offer.price_montage | thousandsSeparator}}</span>
        </span>

        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_disposal >0 &&  data.inquiry.service_trash_per_cubic">
          <span>
            <input type="checkbox" class="movline-checkbox" formControlName="price_disposal">Entsorgung
            pro m3:
          </span>
          <span>{{data.offer.price_disposal | thousandsSeparator}}</span>
        </span>

        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_service_mount_lamps >0 && data.inquiry.service_mount_lamps">
          <span>
            <input type="checkbox" class="movline-checkbox" formControlName="price_service_mount_lamps">Lampenmontage:
          </span>
          <span>{{data.offer.price_service_mount_lamps | thousandsSeparator}}</span>
        </span>

        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_service_floorliner > 0 && data.inquiry.service_floorliner">
          <span>
            <input type="checkbox" class="movline-checkbox" formControlName="price_service_floorliner">Floorliner:
          </span>
          <span>{{data.offer.price_service_floorliner | thousandsSeparator}}</span>
        </span>

        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_service_boxes >0 &&  data.inquiry.service_boxes">
          <span>
            <input type="checkbox" class="movline-checkbox" formControlName="price_service_boxes">Kisten einpacken:
          </span>
          <span>{{data.offer.price_service_boxes | thousandsSeparator}}</span>
        </span>

        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_services_clothes_boxes >0  && data.inquiry.services_clothes_boxes">
          <span>
            <input type="checkbox" class="movline-checkbox"
              formControlName="price_services_clothes_boxes">Kleiderboxenmiete:
          </span>
          <span>{{data.offer.price_services_clothes_boxes | thousandsSeparator}}</span>
        </span>

        <span style="display: flex; flex-direction: row; justify-content: space-between;"
          *ngIf="data.offer.price_services_furniture_lift >0 && data.inquiry.services_furniture_lift">
          <span>
            <input type="checkbox" class="movline-checkbox" formControlName="price_services_furniture_lift">Möbellift:
          </span>
          <span>{{data.offer.price_services_furniture_lift | thousandsSeparator}}</span>
        </span>

      </div>

      <h2 [ngClass]="{ 'price': true, 'hidden': form.get('offer')?.value != data.offer.uuid }">
        Total: CHF {{(total_price) | thousandsSeparator}}</h2>

      <!-- <h2 [ngClass]="{ 'price': true}">
              Total: CHF {{calculateTotalPrice(data.inquiry, data.offer)| thousandsSeparator}}</h2> -->
    </div>
    <br>
    <br>
    <div *ngIf="data.withOptionToFinish">
      <hr>
      <div>
        <br>
        <p class="paragraph-9">Wählen Sie bitte die gewünschte Zahlungsart.
        </p>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Zahlungsart</mat-label>
          <mat-select formControlName="payment_method">
            <mat-option [value]="'invoice'">
              Rechnung
            </mat-option>
            <mat-option [value]="'cash_at_location'">
              Bar vor Ort
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br>
        <div style="display: flex; justify-content: space-around;" class="mb-5">
          <label class="w-checkbox">
            <input type="checkbox" id="checkbox-3" name="checkbox-3" formControlName="terms_accepted"
              data-name="Checkbox 3" class="w-checkbox-input movline-checkbox">
            <span class="w-form-label" for="checkbox-3">
              Ich akzeptiere die <a href="https://www.movline24.ch/agb" target="_blank">AGBs</a>
            </span>
          </label>
        </div>
        <!-- <input type="submit" (click)="onSubmit()" data-wait="Please wait..." class="submit-button w-button"
        value="Angebot annehmen"> -->
      </div>
    </div>

  </form>
  <!-- <pre>{{form.value | json}}</pre>
  <pre >{{data | json}}</pre> -->

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">Schliessen</button>
  <button mat-button color="primary" (click)="save()" [disabled]="total_price == 0.0" cdkFocusInitial
    *ngIf="!data.withOptionToFinish">Offerte
    auswählen</button>

  <button mat-button color="primary" (click)="save()" [disabled]="total_price == 0.0" cdkFocusInitial
    *ngIf="data.withOptionToFinish">Offerte
    annehmen</button>

</div>
