import { Component } from "@angular/core";

@Component({
  selector: "app-side-bar-bottom",
  template: `
    <div class="div mb-3" >
      <div class="div-2">
        <a href="https://www.movline24.ch/agb" target="_blank" style="text-decoration: none;" class="px-2">
          AGB
        </a>
        <a href="https://www.movline24.ch/kontakt" target="_blank" style="text-decoration: none;" class="px-2">
        KONTAKT
        </a>
      </div>
        <div class="div-3">
          <a href="https://www.movline24.ch/datenschutz" target="_blank" style="text-decoration: none;" class="px-2">
            DATENSCHUTZ
          </a>
      </div>
    </div>
  `,
  styles: [
    `
      .div {
        display: flex;
        margin-top: 41px;
        padding-left: 18px;
        flex-direction: column;
        align-items: center;
      }
      @media (max-width: 991px) {
        .div {
          margin-top: 40px;
        }
      }
      a {
        color: #21adac;
      }
      .img {
        aspect-ratio: 7.13;
        object-fit: contain;
        object-position: center;
        width: 107px;
        overflow: hidden;
      }
      .div-2 {
        color: #21adac;
        text-align: center;
        align-self: stretch;
        margin-top: 20px;
        white-space: nowrap;
        font: 700 12px Poppins, sans-serif;
      }
      @media (max-width: 991px) {
        .div-2 {
          white-space: initial;
        }
      }
      .div-3 {
        color: #21adac;
        text-align: center;
        align-self: stretch;
        margin-top: 10px;
        white-space: nowrap;
        font: 700 12px Poppins, sans-serif;
      }
      @media (max-width: 991px) {
        .div-3 {
          white-space: initial;
        }
      }
    `,
  ],
})
export class SideBarBottomComponent { }
