import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminUrlConstantsService } from './admin-url-constants.service';
import { Params } from '@angular/router';
import { IAdminOrganization } from '../interfaces/organization.interface';
import { IAdminDetailedUser } from '../interfaces/user.interface';
import { IAdminDetailedInquiry, InquiryStatus } from '../interfaces/inquiry.interface';
import { IAdminDetailedOffer } from '../interfaces/offer.interface';
import { IAdminDetailOrder, MovLineOrderStateType } from '../interfaces/order.interface';
import { PaginatedResponse } from '../utils';


@Injectable({ providedIn: 'root' })
export class AdminService {

  constructor(
    private http: HttpClient,
    private urls: AdminUrlConstantsService,
  ) { }

  getDashboardNumbers(): Observable<any> {
    return this.http.get<any>(this.urls.DASHBOARD_NUMBERS);
  }

  getOrganizations(): Observable<never[]> {
    return this.http.get<any>(this.urls.ORGANIZATIONS);
  }

  getOrganization(organizationUUID: string): Observable<IAdminOrganization> {
    return this.http.get<IAdminOrganization>(this.urls.ORGANIZATIONS_BY_UUID(organizationUUID));
  }

  updateOrganization(organizationUUID: string, data: IAdminOrganization): Observable<IAdminOrganization> {
    return this.http.patch<IAdminOrganization>(this.urls.ORGANIZATIONS_BY_UUID(organizationUUID), data);
  }

  getOrganizationComments(organizationUUID: string, params: Params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.urls.COMMENTS_FOR_ORGANIZATIONS_BY_UUID(organizationUUID), { params });
  }

  addOrganizationComment(organizationUUID: string, data: Params = {}): Observable<any> {
    return this.http.post<any>(this.urls.COMMENTS_FOR_ORGANIZATIONS_BY_UUID(organizationUUID), data);
  }

  getComments(entityUUID: string, params: Params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.urls.COMMENTS_BY_ENTITY_UUID(entityUUID), { params: params });
  }

  addComment(entityUUID: string, data: Params = {}): Observable<any> {
    return this.http.post<any>(this.urls.COMMENTS_BY_ENTITY_UUID(entityUUID), data);
  }

  getUsers(params: Params = {}): Observable<IAdminDetailedUser[]> {
    return this.http.get<IAdminDetailedUser[]>(this.urls.USERS, { params });
  }

  getUser(userUUID: string): Observable<IAdminDetailedUser> {
    return this.http.get<IAdminDetailedUser>(this.urls.USERS_BY_UUID(userUUID));
  }

  updateUser(userUUID: string, data: IAdminDetailedUser): Observable<IAdminDetailedUser> {
    return this.http.patch<IAdminDetailedUser>(this.urls.USERS_BY_UUID(userUUID), data);
  }

  impersonateOrganizationUser(organizationUUID: string,): Observable<any> {
    return this.http.post<any>(this.urls.IMPERSONATE_ORGANIZATIONS_BY_UUID(organizationUUID), {});
  }

  getActivity(params: Params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.urls.EVENTS, { params });
  }

  getInquiries(params: Params = {}): Observable<PaginatedResponse<IAdminDetailedInquiry>> {
    return this.http.get<PaginatedResponse<IAdminDetailedInquiry>>(this.urls.INQUIRIES, { params });
  }

  getInquiry(uuid: string): Observable<IAdminDetailedInquiry> {
    return this.http.get<any>(this.urls.INQUIRY_BY_UUID(uuid));
  }

  updateInquiry(uuid: string, data: any): Observable<IAdminDetailedInquiry> {
    return this.http.patch<any>(this.urls.INQUIRY_BY_UUID(uuid), data);
  }

  deleteInquiry(uuid: string): Observable<IAdminDetailedInquiry> {
    return this.http.patch<any>(this.urls.INQUIRY_BY_UUID(uuid), {
      status: InquiryStatus.DELETED
    });
  }

  getOffers(params: Params = {}): Observable<IAdminDetailedOffer[]> {
    return this.http.get<any[]>(this.urls.OFFERS, { params });
  }

  getOrder(orderUUID: string) {
    return this.http.get<IAdminDetailOrder>(this.urls.ORDERS_BY_UUID(orderUUID));
  }

  getOrders(params: Params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.urls.ORDERS, { params });
  }

  updateOrder(orderUUID: string, data: any) {
    return this.http.patch<IAdminDetailOrder>(this.urls.ORDERS_BY_UUID(orderUUID), data);
  }

  getOffer(uuid: string): Observable<IAdminDetailedOffer> {
    return this.http.get<IAdminDetailedOffer>(this.urls.OFFERS_BY_UUID(uuid));
  }

  deleteOffer(uuid: string): Observable<IAdminDetailedInquiry> {
    return this.http.delete<any>(this.urls.OFFERS_BY_UUID(uuid));
  }

  getRatings(params: Params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.urls.RATINGS, { params });
  }

  patchRating(uuid: string, value: any): Observable<any> {
    return this.http.patch<any>(this.urls.RATINGS_BY_UUID(uuid), value);
  }

  markOrderAsDone(orderUUID: string): Observable<unknown> {
    return this.http.patch(this.urls.ORDERS_BY_UUID_MARK_DONE(orderUUID), { status: MovLineOrderStateType.DONE })
  }
}
