<div class="container">


  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h1>Anfragen</h1>
  </div>

  <div>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Filter by Status</mat-label>
      <mat-select multiple [(value)]="selectedStatuses" (selectionChange)="onStatusFilterChange()">
        <mat-option *ngFor="let status of statuses" [value]="status.value">
          {{ status.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- <pre>{{selectedStatuses | json}}</pre> -->

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-table">

      <!-- Define Columns -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Wann </th>
        <td mat-cell *matCellDef="let element"> {{element.updated_at | humanDatePipe}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">

          {{element.status | inquiryStatusPipe}}
          <span *ngIf="element.tenant">
            <br>
            <span class="badge badge-warning" style="background-color: yellowgreen;">DIREKT</span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef> Von </th>
        <td mat-cell *matCellDef="let element"> {{element.from_address }}
          <br>
          {{element.from_zip }} {{element.from_location }}
        </td>
      </ng-container>

      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef> Nach </th>
        <td mat-cell *matCellDef="let element"> {{element.to_address }}
          <br>
          {{element.to_zip }} {{element.to_location }}
        </td>
      </ng-container>


      <ng-container matColumnDef="offers">
        <th mat-header-cell *matHeaderCellDef> Offerten </th>
        <td mat-cell *matCellDef="let element"> {{element.offer_objects.length}}
        </td>
      </ng-container>
      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button color="primary" (click)="onClickDetails(element.uuid)">Details</button>
        </td>
      </ng-container>



      <!-- Add More Columns as Needed -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Pagination Controls -->
    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]"
      (page)="onPaginateChange($event)">
    </mat-paginator>
  </div>

  <div *ngIf="!isLoading && totalItems == 0 ">
    <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
    <div class="alert alert-info mt-2">Keine Anfragen</div>
  </div>

  <div class="alert alert-info mt-2">
    <h5>
      Anfrage Status sind:
    </h5>
    <pre>
      DRAFT -> user hat nur angefangen aber hat abgebrochen
      OPEN -> user hat anfrage angeschickt und wartet auf freigabe
      ACCEPTED  -> admin hat die Anfrage akzeptiert
      OPEN_FOR_BIDS -> Anfrage kann von Firmen gesehen und Firmen koennen Angebote machen
      ASSIGNED -> Anfrage wurde an eine Firma zugewiesen
      DONE -> Auftrag wurde bearbeitet
    </pre>
  </div>
</div>
