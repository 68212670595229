<body class="body">
  <app-inquiry-header [step]="1" [withProgressbar]="false"></app-inquiry-header>
  <div class="step-1">

    <div class="step-1-wrapper">
      <div class="w-form">

        <form class="flexbox-row d-flex align-items-center  " [formGroup]="draftInquiryForm">
          <div>

            <div class="row">
              <div class="">
                <h2>Service</h2>
              </div>
            </div>

            <div class="row ">
              <div class=" col-sm-12 col-md-6">
                <div class="cls-input-area   ">
                  <div name="services">

                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Service wählen</mat-label>
                      <mat-select formControlName="service">
                        <mat-option value="1">
                          Umzug und Reinigung
                        </mat-option>
                        <mat-option value="2">
                          Nur Umzug
                        </mat-option>
                        <mat-option value="3">
                          Nur Reinigung
                        </mat-option>
                        <mat-option value="4">
                          Klavierumzug
                        </mat-option>
                        <mat-option value="5">
                          Büroumzug
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class=" col-sm-12 col-md-6"
                *ngIf="draftInquiryForm.value.service == inquiryServiceType.MOVE_AND_CLEAN || draftInquiryForm.value.service == inquiryServiceType.MOVE|| draftInquiryForm.value.service == inquiryServiceType.PIANO_MOVE || draftInquiryForm.value.service == inquiryServiceType.OFFICE_MOVE">

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Umzugsdatum</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="moving_date" [matDatepickerFilter]="futureDatesOnly" >
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <!-- <mat-error *ngIf="exampleForm.controls['selectedDate'].hasError('required')">
      Please select a date
    </mat-error> -->
                </mat-form-field>

              </div>

              <div class=" col-sm-12 col-md-6"
                *ngIf="draftInquiryForm.value.service == inquiryServiceType.MOVE_AND_CLEAN || draftInquiryForm.value.service == inquiryServiceType.CLEAN">

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Reinigungsdatum</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="cleaning_date" [matDatepickerFilter]="futureDatesOnly" >
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <!-- <mat-error *ngIf="exampleForm.controls['selectedDate'].hasError('required')">
                    Please select a date
                  </mat-error> -->
                </mat-form-field>

              </div>

            </div>

            <div class="row">
              <div class="">
                <h2>Persönliche Informationen</h2>
              </div>
            </div>

            <div class="row">
              <div class=" col-sm-12 col-md-6">
                <h4>Anrede</h4>
                <div class="herr-frau">

                  <mat-radio-group formControlName="gender">
                    <mat-radio-button value="man">Mann</mat-radio-button>
                    <mat-radio-button value="woman">Frau</mat-radio-button>
                  </mat-radio-group>

                </div>
              </div>

            </div>

            <div class="row">
              <div class=" col-sm-12 col-md-6">
                <h4>Name</h4>
                <div class="cls-input-area   ">

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Vorname</mat-label>
                    <input matInput formControlName="first_name">
                  </mat-form-field>
                </div>

                <div class="cls-input-area   ">

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Nachname</mat-label>
                    <input matInput formControlName="last_name">
                  </mat-form-field>

                </div>
              </div>
              <div class=" col-sm-12 col-md-6">
                <h4 class="contact-margin">Kontakt</h4>
                <div class="cls-input-area   ">

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                  </mat-form-field>

                </div>

                <div class="mobile-phone-container cls">
                  <div class="cls-input-area   ">

                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Telefon</mat-label>
                      <input matInput formControlName="phone">
                    </mat-form-field>

                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="">
                <h2 class="margin-reduced-16">Adressen</h2>
              </div>
            </div>

            <div class="row address-row">
              <div class=" col-sm-12 col-md-6 col-lg-6">

                <h4 class="pull-left">Jetzige Adresse</h4>

                <div class="cls-address-area">
                  <div class="address-wrapper">
                    <div class="col-12 col-sm-6 no-padding-left no-padding-right-xs col-sm-12 no-padding-right-sm">

                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Strasse und Hausnummer</mat-label>
                        <input matInput formControlName="from_address">
                      </mat-form-field>

                      <div class="div">
                        <div class="div-results-options inactive"></div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 no-padding-right no-padding-left-xs col-sm-12 no-padding-right-sm">
                      <div class=" no-padding-right no-padding-left-xs no-padding-left-sm">

                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>PLZ</mat-label>
                          <input matInput formControlName="from_zip">
                        </mat-form-field>

                      </div>
                      <div class="no-padding-left no-padding-right">

                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Stadt</mat-label>
                          <input matInput formControlName="from_location">
                        </mat-form-field>

                      </div>
                      <p class="input-message error-message small additional-message zip-message zip-half-message"></p>
                      <p class="input-message small additional-message hidden-message"></p>
                    </div>
                  </div>
                </div>
              </div>

              <div class=" col-sm-12 col-md-6 col-lg-6"
                *ngIf="draftInquiryForm.value.service != inquiryServiceType.CLEAN">
                <h4 class="pull-left">Neue Adresse</h4>
                <div class="cls-address-area">
                  <div class="address-wrapper">
                    <div class="col-12 col-sm-6 no-padding-left no-padding-right-xs col-sm-12 no-padding-right-sm">

                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Strasse und Hausnummer</mat-label>
                        <input matInput formControlName="to_address">
                      </mat-form-field>

                      <div class="div">
                        <div class="div-results-options inactive"></div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 no-padding-right no-padding-left-xs col-sm-12 no-padding-right-sm">
                      <div class=" no-padding-right no-padding-left-xs no-padding-left-sm">

                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>PLZ</mat-label>
                          <input matInput formControlName="to_zip">
                        </mat-form-field>

                      </div>
                      <div class="no-padding-left no-padding-right">

                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Stadt</mat-label>
                          <input matInput formControlName="to_location">
                        </mat-form-field>

                      </div>
                      <p class="input-message error-message small additional-message zip-message zip-half-message"></p>
                      <p class="input-message small additional-message hidden-message"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="">
                <h4 class="margin-reduced-16">Anzahl Zimmer</h4>
                <app-selector name="from_size_rooms" [control]="$any(draftInquiryForm).controls['from_size_rooms']"
                  [optionsList]="selectors.number_of_rooms.options"></app-selector>
              </div>
            </div>

            <div class="row">
              <div class="">
                <h4>Kommentar</h4>
                <span class="comment-span">Wenn Sie zusätzliche Informationen haben oder denken, dass
                  der Dienstanbieter über einen besonderen Umstand Bescheid wissen sollte, schreiben Sie es bitte unten
                  auf.</span>

                <br>
                <mat-form-field appearance="outline" class="w-100 mt-2">
                  <mat-label>Teilen Sie bitte eine Inventarliste und die Anzahl der Umzugskartons mit.</mat-label>
                  <textarea matInput placeholder="ich habe .... " formControlName="comment" rows="3"
                    appearance="outline">
                  </textarea>
                  <!-- <mat-error *ngIf="draftInquiryForm.get('comments')?.invalid">
                    Comments cannot exceed 500 characters
                  </mat-error> -->
                </mat-form-field>
              </div>
            </div>
          </div>

        </form>
        <div class="row">
          <button (click)="onSubmit()" type="submit" data-wait="bitte warten" class="submit-button w-button w-100"
            style="margin-bottom: 20px;">{{submitText}}</button>
        </div>

        <pre *ngIf="environment.envName == 'local_dev'">{{draftInquiryForm.value | json}}</pre>

      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <p>Build {{versions.hash}} von {{versions.date | date: 'yyyy-MM-dd HH:mm'}}</p>
  </div>

</body>
