<section class="header">
  <div class="div-block-4">
    <a href="https://www.movline24.ch/" target="_blank">
      <img src="/assets/logos/movline-logo-white.png" loading="lazy" width="207" alt="">
    </a>
  </div>
</section>
<div class="step-1-copy" *ngIf="withProgressbar">
  <div id="w-node-_38963721-46df-59b8-41fd-aa73c878eeb6-ff93a23e"
    [ngClass]="['w-layout-layout', 'grid-progrerss', 'wf-layout-layout', getGridClass()]">

    <div id="w-node-_38963721-46df-59b8-41fd-aa73c878eeb7-ff93a23e" class="w-layout-cell cell"
    [ngClass]="{'hide': step != 1 }"
    >
      <div class="progresse-content-wrapper" [ngClass]="{'hide': step != 1}">
        <img src="/assets/inquiry/iconoir_page.png" loading="lazy" width="24" alt="">
        <p class="paragraph-3">Anfrage starten</p>
      </div>
    </div>

    <div id="w-node-_38963721-46df-59b8-41fd-aa73c878eeb8-ff93a23e" class="w-layout-cell cell " [ngClass]="{'hide': step != 2 }"
      *ngIf="inquiryServiceType != inquiryServiceTypes.CLEAN">
      <div class="progresse-content-wrapper " [ngClass]="{'hide': step != 2}">
        <img src="/assets/inquiry/bi_house-down-1.png" loading="lazy" width="24" alt="">
        <p class="paragraph-3">Jetziges Zuhause</p>
      </div>
    </div>

    <div id="w-node-_38963721-46df-59b8-41fd-aa73c878eeb8-ff93a23e" class="w-layout-cell cell "
      [ngClass]="{'hide': step != 3 }" *ngIf="inquiryServiceType == inquiryServiceTypes.CLEAN">
      <div class="progresse-content-wrapper" [ngClass]="{'hide': step != 3}">
        <img src="/assets/inquiry/bi_house-down-1.png" loading="lazy" width="24" alt="">
        <p class="paragraph-3">Reinigungsdetails</p>
      </div>
    </div>

    <div id="w-node-a7a3dc10-f7b6-8e73-bcde-5495e87a72a7-ff93a23e" class="w-layout-cell cell " [ngClass]="{'hide': step != 3 }"
      *ngIf="inquiryServiceType != inquiryServiceTypes.CLEAN">
      <div [ngClass]="{'hide': step != 3, 'progresse-content-wrapper': true}">
        <img src="/assets/inquiry/bi_house-down-1.png" loading="lazy" width="24" alt="">
        <p class="paragraph-3">Neues Zuhause</p>
      </div>
    </div>

    <div id="w-node-_5699f49d-7d19-e80a-e9a8-bda900161215-ff93a23e" class="w-layout-cell cell " [ngClass]="{'hide': step != 4}"
      *ngIf="inquiryServiceType != inquiryServiceTypes.CLEAN">
      <div class="progresse-content-wrapper " [ngClass]="{'hide': step != 4}">
        <img src="/assets/inquiry/Vector.png" loading="lazy" width="24" alt="">
        <p class="paragraph-3">Inventar</p>
      </div>
    </div>


    <div id="w-node-_1a9ea346-a532-afc6-a3a8-5c5411b80b69-ff93a23e" class="w-layout-cell cell " [ngClass]="{'hide': step != 6}">
      <div class="progresse-content-wrapper " [ngClass]="{'hide': step != 6}">
        <img src="/assets/inquiry/iconoir_page-edit.png" loading="lazy" width="24" alt="">
        <p class="paragraph-3">Bestätigung</p>
      </div>
    </div>
  </div>
  <div>
    <div class="progresse-grey">
      <div class="progresse-green"></div>
    </div>
  </div>
</div>

<!-- <pre>{{step}} von {{numberOfElements}}</pre> -->
<!-- <pre>{{inquiryServiceType | json}}</pre> -->
