<div class="container" *ngIf="item">
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h1>
      Offerte #{{item.id}} von {{item.organization_object.name}}
    </h1>

    <a mat-icon-button href="{{environment.apiDomain}}/admin/accounts/movlineoffer/{{item.id}}/change/" target="_blank">
      <mat-icon>edit</mat-icon>
    </a>
  </div>
  <a routerLink="/main/admin/inquiries/{{item.inquiry_object.uuid}}">Link zur Anfrage</a>
  <br>
  <a routerLink="/main/admin/organizations/{{item.organization_object.uuid}}">Link zur Organization</a>
</div>

<div class="container" *ngIf="item">
  <div class="row" *ngIf="!!item">
    <div class="col-12">
      <mat-card class="mt-3">
        <mat-card-content>
          <ul class="co-simple-list">
            <li>updated_at: {{item.updated_at}}</li>
            <li>author name: {{item.author_object.first_name}} {{item.author_object.last_name}}</li>
            <li>author phone: {{item.author_object.phone}}</li>
            <li>author email: {{item.author_object.email}}</li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row" *ngIf="!!item">
    <div class="col-12">
      <mat-card class="mt-3">
        <mat-card-content>
          <ul class="co-simple-list">

            <li>price_move: {{item.price_move}}</li>
            <li>price_montage: {{item.price_montage}}</li>
            <li>price_cleaning: {{item.price_cleaning}}</li>
            <li>price_disposal: {{item.price_disposal}}</li>
            <li>price_service_mount_lamps: {{item.price_service_mount_lamps}}</li>
            <li>price_service_floorliner: {{item.price_service_floorliner}}</li>
            <li>price_service_boxes: {{item.price_service_boxes}}</li>
            <li>price_services_clothes_boxes: {{item.price_services_clothes_boxes}}</li>
            <li>price_services_furniture_lift: {{item.price_services_furniture_lift}}</li>









          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <pre *ngIf="environment.envName == 'local_dev'">
    {{item | json}}
  </pre>



</div>
