import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (request.url.includes('login')) {
          console.warn('could not log in user with email and password');
          this.authService.logout(true, window.location.pathname);
          return throwError(err?.error);
        }

        // auto logout and handle error if 401 response returned from API
        if (err.status === 401) {
          this.authService.logout(true);
          this.router.navigate(['/login'])
          console.warn('received a 401');
          if (err?.details) {
            console.warn(err.details);
          }
          if (err?.code) {
            console.warn(err.code);
          }
          return throwError(err?.error);
        }

        if (err.status === 403) {
          this.authService.logout(true);
          console.warn('received a 403');
          if (err?.details) {
            console.warn(err.details);
          }
          if (err?.code) {
            console.warn(err.code);
          }
          return throwError(err?.error);
        }



        if (err.status === 500) {
          console.error('internal server error');
          this.toastrService.error(
            'Ups, Der Server hatte einen internen Fehler. Bitte versuchen Sie es später erneut',
            'Fehler',
            { closeButton: true }
          );
        }

        if (err.status === 502 || err.status === 504) {
          this.toastrService.error(
            'Anscheinend sind Sie offline. Gehen Sie bitte wieder online und versuchen Sie es erneut',
            'Fehler',
            { closeButton: true }
          );
        }

        // if (this.onlineService.onlineMode.value){
        //   return throwError(err?.error);
        // } else {
        //   this._toastrService.error(
        //     'It seems like you are offline, pls get back online and try again',
        //     'Error',
        //     { closeButton: true }
        //   );
        //   return throwError(err?.error);
        // }

        // err.error will give the details payload from the server
        return throwError(err?.error);
      })
    );
  }
}
