import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NUMBER_OF_ROOMS_MAPPING_LIST, FROM_SIZE_SQUARE_METERS, SIZE_FLOORS } from 'projects/webapp/app/constants';
import { environment } from 'projects/webapp/app/environments/environment';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { outputErrors } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-inquiry-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class InquiryStep2Component implements OnInit {

  public draftInquiryForm = new FormGroup({
    from_type: new FormControl('', Validators.required),
    from_size_rooms: new FormControl("0", Validators.required),
    from_size_square_meters: new FormControl("0", Validators.required),
    from_size_floors: new FormControl("0", Validators.required),
    from_type_of_elevator: new FormControl("0", Validators.required),

    from_has_more_than_5_meters: new FormControl(false, Validators.required),
    from_way_from_parking_meters: new FormControl("0", Validators.required),

    from_has_stair_steps: new FormControl(false, Validators.required),
    from_number_of_stair_steps: new FormControl("0", Validators.required),

    from_other_difficulties: new FormControl(false, Validators.required),
    from_other_difficulties_description: new FormControl(''),

    service: new FormControl('1', Validators.required),

  });

  environment = environment

  isLoading: boolean = false;
  currentUUID: string = ''

  selectors = {
    number_of_rooms: {
      fieldName: 'Zimmer',
      options: NUMBER_OF_ROOMS_MAPPING_LIST
    },
    from_size_square_meters: {
      fieldName: 'Wohnfläche',
      options: FROM_SIZE_SQUARE_METERS
    },
    from_size_floors: {
      fieldName: 'Stockwerke',
      options: SIZE_FLOORS
    },

    from_way_from_parking_meters: {
      fieldName: 'Distanz in Meter ca.',
      options: [
        { value: "0", display: "k.A." },
        { value: "10", display: "10" },
        { value: "20", display: "20" },
        { value: "30", display: "30" },
        { value: "40", display: "40" },
        { value: "50", display: "50" },
        { value: "60", display: "60" },
        { value: "70", display: "70" },
        { value: "80", display: "80" },
        { value: "90", display: "90" },
        { value: "100", display: "+100" },
      ]
    },
    from_number_of_stair_steps: {
      fieldName: 'Anzahl Stufen ca.',
      options: [
        { value: "0", display: "k.A." },
        { value: "10", display: "10" },
        { value: "20", display: "20" },
        { value: "30", display: "30" },
        { value: "40", display: "40" },
        { value: "50", display: "50" },
        { value: "60", display: "60" },
        { value: "70", display: "70" },
        { value: "80", display: "80" },
        { value: "90", display: "90" },
        { value: "100", display: "+100" },
      ]
    },
  }

  constructor(
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private publicInquiryService: PublicInquiryService
  ) {
  }

  async ngOnInit() {
    this.draftInquiryForm.get('service')?.valueChanges.subscribe((value) => {
      const service = this.draftInquiryForm.get('service');
      if (service && service.value) {
        this.publicInquiryService.inquiryServiceType$.next(service.value as InquiryServiceType)
      }
    });

    let currentUUID = localStorage.getItem('currentInquiryUUID');
    if (!currentUUID) {
      this.route.queryParams.subscribe(async (params) => {
        currentUUID = params['currentInquiryUUID']
        console.log(currentUUID)
        if (currentUUID) {
          this.currentUUID = currentUUID;
          const response = await lastValueFrom(this.publicInquiryService.getEnquiry(this.currentUUID))
          this.draftInquiryForm.patchValue(response);
        } else {
          this.router.navigate(['/inquiry/1'])
        }
      });
    }

  }

  async onSubmit() {
    this.draftInquiryForm.markAllAsTouched()
    if (!this.draftInquiryForm.valid) {
      outputErrors(this.draftInquiryForm)
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.updateEnquiry(this.currentUUID, this.draftInquiryForm.value))
      this.spinnerService.hide()
      if (response.service == InquiryServiceType.MOVE) {
        this.router.navigate(['/inquiry/4'], { queryParamsHandling: 'merge' });
      } else {
        this.router.navigate(['/inquiry/3'], { queryParamsHandling: 'merge' });
      }
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.isLoading = false;
    }
  }

}
