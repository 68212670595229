import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { versions } from 'projects/versions';
import { environment } from 'projects/webapp/app/environments/environment';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { outputErrors } from 'projects/webapp/app/utils';
import { tap, debounceTime, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-inquiry-extend',
  templateUrl: './extend.component.html',
  styleUrls: ['./extend.component.scss']
})
export class InquiryExtendComponent implements OnInit {

  submitText = 'ANFRAGE STARTEN'
  versions = versions;

  public draftInquiryForm = new FormGroup({
    uuid: new FormControl(''),
    // from_zip: new FormControl('', Validators.required),
    // from_location: new FormControl('', Validators.required),
    // to_zip: new FormControl('', Validators.required),
    // to_location: new FormControl('', Validators.required),

    // service: new FormControl('1', Validators.required),

    // email: new FormControl('', Validators.required),
    // phone: new FormControl('', Validators.required),

    what_to_clean_all_rooms: new FormControl(false, Validators.required),
    what_to_clean_kitchen_toilet: new FormControl(false, Validators.required),
    what_to_clean_attic: new FormControl(false, Validators.required),
    what_to_clean_winter_garden: new FormControl(false, Validators.required),
    what_to_clean_balcony: new FormControl(false, Validators.required),
    what_to_clean_basement: new FormControl(false, Validators.required),

    special_clean_build_in_wardrobes: new FormControl(false, Validators.required),
    special_clean_hardened_dirt: new FormControl(false, Validators.required),
    special_clean_mold: new FormControl(false, Validators.required),
    special_clean_has_stuff: new FormControl(false, Validators.required),
    special_clean_has_washing_machine: new FormControl(false, Validators.required),
    special_clean_has_pets: new FormControl(false, Validators.required),
    special_clean_has_cheminee: new FormControl(false, Validators.required),
    special_clean_shampoo_carpet: new FormControl(false, Validators.required),
    special_clean_shampoo_carpet_number: new FormControl(''),

    number_of_showers: new FormControl('1'),
    number_of_toilets: new FormControl('1'),
    number_of_sinks: new FormControl('1'),

    window_normal: new FormControl('1'),
    window_wall: new FormControl('1'),
    window_doors: new FormControl('1'),

    service: new FormControl('1', Validators.required),

    tenant: new FormControl(null),
  });

  cachedKey = 'currentInquiry'
  isLoading = false;

  environment = environment;
  inquiryServiceType = InquiryServiceType

  selectors = {
    number_of_showers: {
      fieldName: 'Duschen / Badewannen',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    number_of_toilets: {
      fieldName: 'Toiletten',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    number_of_sinks: {
      fieldName: 'Lavabos',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },

    window_normal: {
      fieldName: 'Normale Fenster',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    window_wall: {
      fieldName: 'Fensterwände',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    window_doors: {
      fieldName: 'Fenstertüren',
      options: [
        { value: "0", display: "0" },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
  }

  constructor(
    private spinnerService: NgxSpinnerService,
    private publicInquiryService: PublicInquiryService,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.draftInquiryForm.get('service')?.valueChanges.subscribe((value) => {
      const service = this.draftInquiryForm.get('service');

      if (service && service.value) {
        this.publicInquiryService.inquiryServiceType$.next(service.value as InquiryServiceType)
      }

      // const from_zip = this.draftInquiryForm.get('from_zip');
      // const from_location = this.draftInquiryForm.get('from_location');
      // const to_zip = this.draftInquiryForm.get('to_zip');
      // const to_location = this.draftInquiryForm.get('to_location');
      // if (service?.value == InquiryServiceType.CLEAN) {
      //   from_zip?.clearValidators()
      //   from_location?.clearValidators()
      //   to_zip?.clearValidators()
      //   to_location?.clearValidators()
      // } else {
      //   from_zip?.setValidators([Validators.required]);
      //   from_location?.setValidators([Validators.required]);
      //   to_zip?.setValidators([Validators.required]);
      //   to_location?.setValidators([Validators.required]);
      // }
      // from_zip?.updateValueAndValidity()
      // from_location?.updateValueAndValidity()
      // to_zip?.updateValueAndValidity()
      // to_location?.updateValueAndValidity()
    });

    this.draftInquiryForm.valueChanges.pipe(
      tap(() => (this.isLoading = true)),
      debounceTime(300)
    ).subscribe(val => {
      console.log(val)
      localStorage.setItem(this.cachedKey, JSON.stringify(val));
    });

    const val = localStorage.getItem(this.cachedKey);
    if (!!val) {
      console.log('had a current inquiry in the session')
      try {
        this.draftInquiryForm.patchValue(JSON.parse(val))
      } catch (error) {
        console.error(error)
        localStorage.removeItem(this.cachedKey)
      }
    }

    this.route.queryParams.subscribe(params => {
      const tenant = params['tenant']
      if (!!tenant) {
        console.log(`using a tenant id ${tenant}`)
        this.draftInquiryForm.patchValue({
          tenant: tenant
        });
      }

      const service = params['service']
      if (!!service) {
        if (service == 'clean') {
          console.log('going to only for clean')
          this.draftInquiryForm.patchValue({
            service: '3'
          });
        }
        if (service == 'move') {
          console.log('going to only for moving')
          this.draftInquiryForm.patchValue({
            service: '2'
          });
        }
      }
    });

    const currentUUID = localStorage.getItem('currentInquiryUUID');
    if (currentUUID) {
      try {
        this.draftInquiryForm.patchValue({ uuid: currentUUID });
      } catch (error) {
        console.error(error)
        localStorage.removeItem('currentInquiryUUID')
      }
      this.submitText = 'ANFRAGE BEARBEITEN'
    }
  }

  async onSubmit() {
    this.draftInquiryForm.markAllAsTouched()
    if (!this.draftInquiryForm.valid) {
      console.log(this.draftInquiryForm.errors)
      outputErrors(this.draftInquiryForm)
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show();

      const currentUUID = localStorage.getItem('currentInquiryUUID');
      if (currentUUID) {
        this.draftInquiryForm.patchValue({ uuid: currentUUID })
      }

      // const token = await lastValueFrom(this.recaptchaV3Service.execute('register'))
      // this.form.patchValue({ re_captcha_response: token })
      const response = await lastValueFrom(this.publicInquiryService.createEnquiry(this.draftInquiryForm.value))
      if (!!response.uuid)
        localStorage.setItem('currentInquiryUUID', response.uuid);
      if (response.service == InquiryServiceType.CLEAN) {
        this.router.navigate(['/inquiry/3']);
      } else {
        this.router.navigate(['/inquiry/2']);
      }
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }

}
