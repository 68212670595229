<div class="container">
  <div *ngIf="item">
    <h2>Service</h2>
    <ul class="co-simple-list">
      <li>Service: <b>{{item.service | serviceTypePipe}}</b></li>
      <li *ngIf="item.service != inquiryServiceType.CLEAN">Umzugsdatum: {{item.moving_date | date: 'yyyy-MM-dd'}}</li>
      <li>Putzdatum: {{(item.cleaning_date | date: 'yyyy-MM-dd') || 'k.A.'}}</li>
      <li>Übergabedatum: {{(item.handover_date | date: 'yyyy-MM-dd') || 'k.A.'}}</li>
    </ul>

    <div *ngIf="item.service == inquiryServiceType.CLEAN">
      <hr>

      <h2>Objekt</h2>

      <div class="row">
        <div class="col-12">
          <mat-card class="mt-3">
            <mat-card-content>
              <ul class="co-simple-list">
                <li>Wo: {{item.from_address}}, {{item.from_zip}} {{item.from_location}}</li>
                <li>Typ: {{item.from_type | propertyTypePipe}}</li>
                <li>Anzahl Zimmer: {{item.from_size_rooms | numberOfRoomsPipe}}</li>
                <li>Quadratmeter: {{item.from_size_square_meters | sizeSquareTypePipe}}</li>
              </ul>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div *ngIf="item.service == inquiryServiceType.MOVE || item.service == inquiryServiceType.MOVE_AND_CLEAN">
      <hr>

      <h2>Umzug</h2>

      <div class="row">
        <div class="col-6">
          <mat-card class="mt-3">
            <mat-card-content>
              <ul class="co-simple-list">
                <li>Von: {{item.from_address}}, {{item.from_zip}} {{item.from_location}}</li>
                <li>Typ: {{item.from_type | propertyTypePipe}}</li>
                <li>Anzahl Zimmer: {{item.from_size_rooms | numberOfRoomsPipe}}</li>
                <li>Quadratmeter: {{item.from_size_square_meters | sizeSquareTypePipe}}</li>
              </ul>

              <br>

              <ul class="co-simple-list">
                <li>Stockwerke: {{item.from_size_floors | genericValueDisplayPipe: SIZE_FLOORS}}</li>
                <li>Lift: {{item.from_type_of_elevator | elevatorTypePipe}}</li>
                <li>Weg vom Parkplatz länger als 5 Meter: {{item.from_has_more_than_5_meters | yesNoPipe}}</li>
                <li *ngIf="item.from_has_more_than_5_meters">Distanz in Meter vom Parkplatz ca. (in Meter):
                  {{item.from_way_from_parking_meters}}</li>
                <li>Weg vom Parkplatz hat Stufen: {{item.from_has_stair_steps | yesNoPipe}}</li>
                <li *ngIf="item.from_has_stair_steps">Anzahl Stufen ca.: {{item.from_number_of_stair_steps}}</li>
                <li>Hat Schwierigkeiten: {{item.from_other_difficulties | yesNoPipe}}</li>
                <li *ngIf="item.from_other_difficulties">Schwierigkeiten: {{item.from_other_difficulties_description}}
                </li>
              </ul>

            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-6">
          <mat-card class="mt-3">
            <mat-card-content>
              <ul class="co-simple-list">
                <li>Nach: {{item.to_address}}, {{item.to_zip}} {{item.to_location}}</li>
                <li>Typ: {{item.to_type | propertyTypePipe}}</li>
                <li>Anzahl Zimmer: {{item.to_size_rooms | numberOfRoomsPipe}}</li>
                <li>Quadratmeter: {{item.to_size_square_meters | sizeSquareTypePipe}}</li>
              </ul>

              <br>

              <ul class="co-simple-list">
                <li>Stockwerke: {{item.to_size_floors | genericValueDisplayPipe: SIZE_FLOORS}}</li>
                <li>Lift: {{item.to_type_of_elevator | elevatorTypePipe}}</li>
                <li>Weg vom Parkplatz länger als 5 Meter: {{item.to_has_more_than_5_meters | yesNoPipe}}</li>
                <li *ngIf="item.to_has_more_than_5_meters">Distanz in Meter vom
                  Parkplatz ca. (in Meter):
                  {{item.to_way_from_parking_meters}}</li>
                <li>Weg vom Parkplatz hat Stufen: {{item.to_has_stair_steps | yesNoPipe}}</li>
                <li *ngIf="item.to_has_stair_steps">Anzahl Stufen ca.: {{item.to_number_of_stair_steps}}</li>
                <li>Hat Schwierigkeiten: {{item.to_other_difficulties | yesNoPipe}}</li>
                <li *ngIf="item.to_other_difficulties">Schwierigkeiten: {{item.to_other_difficulties_description}}</li>
              </ul>

            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <mat-card class="mt-3">
        <mat-card-content>
          <ul class="co-simple-list">
            <li>Anzahl Personen: {{item.number_of_persons }}</li>
            <li>Anzahl Kartons: {{item.number_of_cartons }}</li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="item.comment">
      <hr>
      <h2>Kommentar (bei der Anfrage)</h2>
      <div>
        <mat-card class="mt-3">
          <mat-card-content>

            <textarea class="w-100" matInput [value]="item.comment" rows="3" appearance="outline" disabled="true">
            </textarea>

          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div *ngIf="item.service == inquiryServiceType.MOVE || item.service == inquiryServiceType.MOVE_AND_CLEAN">
      <hr>
      <h2>Extras</h2>
      <div>
        <mat-card class="mt-3">
          <mat-card-content>
            <ul class="co-simple-list">
              <li>Entsorgung: {{item.service_trash_per_cubic | yesNoPipe}}</li>
              <li>Lampenmontage: {{item.service_mount_lamps | yesNoPipe}}</li>
              <li *ngIf="item.service_mount_lamps">Anzahl Lampen: {{item.service_number_of_lamps }}</li>
              <li>Floorliner: {{item.service_floorliner | yesNoPipe}}</li>
              <li>Kisten einpacken: {{item.service_boxes | yesNoPipe}}</li>
              <li>Kleiderboxen mieten: {{item.services_clothes_boxes | yesNoPipe}}</li>
              <li *ngIf="item.services_clothes_boxes">Anzahl Kleiderboxen: {{item.service_number_of_clothes_boxes }}
              </li>
              <li>Möbellift: {{item.services_furniture_lift | yesNoPipe}}</li>
              <li>Montageservice: {{item.services_assembling | yesNoPipe}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div *ngIf="item.service != inquiryServiceType.CLEAN ">
      <div *ngIf="!!item && !!item.inventar && item.inventar.length != 0">
        <hr>

        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h2>Inventar</h2>
        </div>

        <div>
          <mat-card class="mt-3">
            <mat-card-content>

              <!-- <pre>{{item.inventar | json}}</pre> -->

              <div class="alert alert-info" *ngIf="!item.inventar || item.inventar.length == 0">
                Kein Inventar angegeben.
              </div>

              <div *ngFor="let room of item.inventar">
                <h4>{{room.name}}</h4>
                <table *ngIf="!!room && room.furniture && room.furniture.length"
                  style="width: 100%; border-collapse: collapse;" class="table table-striped">
                  <thead>
                    <td>Was</td>
                    <td>Anzahl</td>
                    <td>Details</td>
                  </thead>

                  <tbody *ngIf="room.furniture">
                    <tr *ngFor="let furniture of room.furniture">
                      <td>{{furniture.name}}</td>
                      <td>{{furniture.number}}</td>
                      <td>{{furniture.details}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div *ngIf="(item.service == inquiryServiceType.CLEAN || item.service == inquiryServiceType.MOVE_AND_CLEAN)">
      <hr>
      <h2>Reinigung</h2>
      <div>
        <mat-card class="mt-3">
          <mat-card-content>

            <ul class="co-simple-list">
              <li>Alle Zimmer: {{item.what_to_clean_all_rooms | yesNoPipe}}</li>
              <li>Küche, Bad &amp; WC: {{item.what_to_clean_kitchen_toilet | yesNoPipe}}</li>
              <li>Dachboden: {{item.what_to_clean_attic | yesNoPipe}}</li>
              <li>Wintergarten: {{item.what_to_clean_winter_garden | yesNoPipe}}</li>
              <li>Balkon: {{item.what_to_clean_balcony | yesNoPipe}}</li>
              <li>Keller/Kellerabteil: {{item.what_to_clean_basement | yesNoPipe}}</li>
              <li>Einbauschranke (exkl. Küche): {{item.special_clean_build_in_wardrobes | yesNoPipe}}</li>
              <li>Stark verhärteter Dreck: {{item.special_clean_hardened_dirt | yesNoPipe}}</li>
              <li>Schimmel an den Fensterrahmen: {{item.special_clean_mold | yesNoPipe}}</li>
              <li>Zum Zeitpunkt der Reinigung möbliert: {{item.special_clean_has_stuff | yesNoPipe}}</li>
              <li>Waschturm: {{item.special_clean_has_washing_machine | yesNoPipe}}</li>
              <li>Haustierhaltung: {{item.special_clean_has_pets | yesNoPipe}}</li>
              <li>Reinigung des Cheminées: {{item.special_clean_has_cheminee | yesNoPipe}}</li>

              <li>Teppich shampoonieren: {{item.special_clean_shampoo_carpet | yesNoPipe}}</li>
              <li *ngIf="item.special_clean_shampoo_carpet">Anzahl Teppiche: {{item.special_clean_shampoo_carpet_number
                }}</li>

              <li>Anzahl Duschen / Badewannen: {{item.number_of_showers }}</li>
              <li>Anzahl Toiletten: {{item.number_of_toilets }}</li>
              <li>Lavabos: {{item.number_of_sinks }}</li>
              <li>Fenster: {{item.window_normal }}</li>
              <li>Fensterwände: {{item.window_wall }}</li>
              <li>Fenstertüren: {{item.window_doors }}</li>
            </ul>

          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div *ngIf="showCommentSections">
      <hr>

      <div class="row">
        <div class="col-12">
          <mat-card class="mt-3">
            <mat-card-content>
              <comments-card-component [title]="'Meine Anfragekommentare als Firma (für mich)'" [adminMode]="false"
                [entityUUID]="this.item.uuid"></comments-card-component>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div *ngIf="showCommentSections">
      <hr>
      <div class="row">
        <div class="col-12">
          <mat-card class="mt-3">
            <mat-card-content>
              <comments-card-component [title]="'Öffentliche Anfragekommentare'" [adminMode]="false" [public]="true"
                [canAddNewComment]="false" [entityUUID]="this.item.uuid"></comments-card-component>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

  </div>

  <pre *ngIf="environment.envName == 'local_dev'">
    {{item | json}}
  </pre>

</div>
