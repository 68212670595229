import { Component, Input, OnInit } from '@angular/core';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';

@Component({
  selector: 'app-inquiry-header',
  templateUrl: './inquiry-header.component.html',
  styleUrls: ['./inquiry-header.component.scss']
})
export class InquiryHeaderComponent implements OnInit {
  inquiryServiceTypes = InquiryServiceType
  inquiryServiceType = InquiryServiceType.MOVE_AND_CLEAN
  numberOfElements  = 5;

  @Input()
  withProgressbar = true;

  @Input()
  step = 1;

  constructor(
    private publicInquiryService: PublicInquiryService,
  ) { }

  ngOnInit(): void {
    this.publicInquiryService.inquiryServiceType$.asObservable().subscribe(val=>{
      this.inquiryServiceType = val;
      if (this.inquiryServiceType == this.inquiryServiceTypes.CLEAN) {
        this.numberOfElements = 3;
      } else {
        this.numberOfElements = 5;
      }
    })
  }

  getGridClass(): string {
    if (this.numberOfElements === 3) {
      return 'three-columns-grid';
    } else {
      return 'default-grid'; // Or any other default configuration
    }
  }

}
