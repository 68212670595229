import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { IRating } from '../../services/dashboard.service';
import { PublicInquiryService } from '../../services/public-inquiry.service';
import { IDetailedOffer } from '../../interfaces/offer.interface';
import { IDetailedInquiry } from '../../interfaces/inquiry.interface';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-company-details-modal',
  templateUrl: './company-details-modal.component.html',
  styleUrls: ['./company-details-modal.component.scss']
})
export class CompanyDetailsModalComponent implements OnInit {
  companyUUID = ''

  org: any = null

  items: IRating[] = [
  ]
  isLoading = false;
  environment = environment;
  average_rating: any;

  constructor(
    public dialogRef: MatDialogRef<CompanyDetailsModalComponent>,
    private spinnerService: NgxSpinnerService,
    private publicInquiryService: PublicInquiryService,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {
      offer: IDetailedOffer,
      inquiry: IDetailedInquiry,
      companyUUID: string
    },
  ) {
    this.companyUUID = data.companyUUID
  }

  async ngOnInit() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.getMyRatings(this.companyUUID));
      this.org = response.organization_object
      this.items = response.ratings
      this.average_rating = response.average_rating

      this.items = this.items.slice(0, 20);
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }

  async onClickRequestVisit() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.requestVisit(this.data.inquiry.uuid, this.data.offer.organization_object.uuid))
      console.log(response)
    } catch (error) {
      console.error(error)
    } finally {
      // this.canRequestVisit = false;
      setTimeout(() => {
        this.spinnerService.hide()
        this.isLoading = false;
        this.toastrService.success('Eine kostenlose Besichtigung wurde angefragt! Die Firma meldet sich bald bei Ihnen.')
      }, 1000);
    }
  }
}
