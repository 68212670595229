import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray } from "@angular/forms";
import { generateFurnitureFormGroup } from "./step5.component";

@Component({
  selector: "app-room",
  template: `

    <form [formGroup]="roomForm">
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <label for="Zimmer-3">Zimmer {{index + 1}}</label>
      <button for="M-belst-ck" style="color: red; font-weight: lighter; background-color: transparent" (click)="onDelete()">Zimmer löschen</button>
    </div>

    <select id="Zimmer-3" name="Zimmer" data-name="Zimmer" class="field-round text-field-6 w-select" formControlName="name">
      <option value="">Bitte auswählen</option>
      <option value="Wohnzimmer">Wohnzimmer</option>
      <option value="Schlafzimmer">Schlafzimmer</option>
      <option value="Esszimmer">Esszimmer</option>
      <option value="Küche">Küche</option>
      <option value="Bad &amp; Gang">Bad &amp; Gang</option>
      <option value="Büro">Büro</option>
      <option value="Dachboden">Dachboden</option>
      <option value="Keller">Keller</option>
      <option value="Another option">Garage</option>
      <option value="Garten / Balkon">Garten / Balkon</option>
      <option value="Kinderzimmer">Kinderzimmer</option>
      <option value="Abstellkammer">Abstellkammer</option>
    </select>

    <div *ngIf="furnitureItemForms">
      <ng-container *ngFor="let roomForm of furnitureItemForms.controls; index as index">
        <div class="mb-5">
          <app-furniture *ngIf="furnitureItemForms"
            [index]="index"
            [furnitureForm]="roomForm"
            [number_of_furnitures]="furnitureItemForms.controls.length"
            (deleteFurniture)="removeFurnitureItem($event)"
          >
          </app-furniture>
        </div>
      </ng-container>
    </div>
    </form>

    <div class="div-block-8">
    <button (click)="onAddNewFurniture()" class="button w-button">weitere Möbel hinzufügen</button>
    </div>
  `,
  styles: [
    `
    .button {
  width: 50%;
  background-color: #146161;
  border-radius: 12px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
    `
  ],
})
export class RoomComponent implements OnInit, AfterViewInit {

  @Input() roomForm: any;
  @Input() index: number = 0;
  @Input() number_of_rooms: number = 0;

  @Output() deleteRoom = new EventEmitter<number>()
  public furnitureItemForms: FormArray | null = null;

  ngOnInit() {
    this.furnitureItemForms = this.roomForm.get('furniture') as FormArray;
  }
  ngAfterViewInit() {
    this.furnitureItemForms = this.roomForm.get('furniture') as FormArray;
  }

  onAddNewFurniture() {
    this.furnitureItemForms = this.roomForm.get('furniture') as FormArray;
    console.log(this.roomForm)
    console.log(this.furnitureItemForms)
    const furnitureItemsCount = this.roomForm.controls.length;
    const furnitureItemForm = generateFurnitureFormGroup();
    if (this.furnitureItemForms) {
      this.furnitureItemForms.push(furnitureItemForm);
    }
  }

  removeFurnitureItem($event: number) {
    if (this.furnitureItemForms) {
      this.furnitureItemForms.removeAt($event);
    }
  }

  onDelete() {
    this.deleteRoom.emit(this.index)
  }
}

