import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminUrlConstantsService {

  environment = environment
  baseUrl = this.environment.baseUrl

  public DASHBOARD_NUMBERS = `${this.environment.baseUrl}/api/v1/admin/dashboard-numbers`;

  public ORGANIZATIONS = `${this.environment.baseUrl}/api/v1/admin/organizations`;
  public ORGANIZATIONS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/organizations/${uuid}`;
  public IMPERSONATE_ORGANIZATIONS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/organizations/${uuid}/impersonate`;
  public COMMENTS_FOR_ORGANIZATIONS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/organizations/${uuid}/comments`;
  public COMMENTS_BY_ENTITY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/entities/${uuid}/comments`;

  public USERS = `${this.environment.baseUrl}/api/v1/admin/users`;
  public USERS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/users/${uuid}`;

  public EVENTS = `${this.environment.baseUrl}/api/v1/admin/events`;
  public INQUIRIES = `${this.environment.baseUrl}/api/v1/admin/inquiries`;
  public INQUIRY_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/inquiries/${uuid}`;
  public OFFERS = `${this.environment.baseUrl}/api/v1/admin/offers`;
  public OFFERS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/offers/${uuid}`;
  public ORDERS = `${this.environment.baseUrl}/api/v1/admin/orders`;
  public ORDERS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/orders/${uuid}`;
  public ORDERS_BY_UUID_MARK_DONE = (uuid: string) => `${this.baseUrl}/api/v1/admin/orders/${uuid}/mark-done`;

  public RATINGS = `${this.environment.baseUrl}/api/v1/admin/ratings`;
  public RATINGS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/ratings/${uuid}`;

  constructor() { }
}
