<body class="body">
  <app-inquiry-header [step]="6"></app-inquiry-header>
  <div class="step-1">
    <h1 class="heading">Kontaktangaben</h1>
    <p class="paragraph">Wir melden uns mit 5 Offerten bei Ihnen</p>
    <div class="step-1-wrapper">
      <div class="w-form">

        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form"
          [formGroup]="draftInquiryForm" data-wf-page-id="65ccf2699b4f869a6cf40f2d"
          data-wf-element-id="3c22ea9e-8587-6cdb-19ca-53e38eea0fb5">

          <div class="main-wrapper">
            <label for="Service-w-hlen" class="field-label box edit big">Ortschaft</label>
            <div>

              <label for="field" class="field-label small"
                *ngIf="!!currentInquiry && currentInquiry.service == inquiryServiceType.CLEAN">Objekt in</label>
              <label for="field" class="field-label small"
                *ngIf="!!currentInquiry && currentInquiry.service != inquiryServiceType.CLEAN">Umzug von</label>
              <input class="field-round w-input" maxlength="256" name="field" data-name="Field"
                formControlName="from_address" placeholder="Strasse &amp; Nr." type="text" id="field">
              <div class="div-block-6">
                <input class="text-field w-input" maxlength="256" name="PLZ" data-name="PLZ" placeholder="PLZ"
                  formControlName="from_zip" type="number" id="PLZ">
                <input class="text-field-5 w-input" maxlength="256" name="Ortschaft" data-name="Ortschaft"
                  formControlName="from_location" placeholder="Stadt" type="text" id="Ortschaft">
              </div>

              <div *ngIf="currentInquiry && currentInquiry.service != inquiryServiceType.CLEAN">
                <label for="field-2" class="field-label small">Umzug nach</label>
                <input class="field-round w-input" maxlength="256" name="field-2" data-name="Field 2"
                  formControlName="to_address" placeholder="Strasse &amp; Nr." type="text" id="field-2">
                <div class="div-block-6">
                  <input class="text-field w-input" maxlength="256" name="PLZ-2" data-name="PLZ 2" placeholder="PLZ"
                    formControlName="to_zip" type="number" id="PLZ-2">
                  <input class="text-field-5 w-input" maxlength="256" name="Ortschaft-2" data-name="Ortschaft 2"
                    formControlName="to_location" placeholder="Stadt" type="text" id="Ortschaft-2">
                </div>
              </div>

            </div>

            <div *ngIf="currentInquiry && currentInquiry.service != inquiryServiceType.CLEAN">
              <label for="Service-w-hlen" class="field-label box edit big _20px-top">Umzugsdatum</label>
              <div class="udesly-input-wrapper-2">
                <input class="field-round kalender w-input" maxlength="256" formControlName="moving_date" name="date-2"
                  data-name="Date 2" placeholder="" type="date" id="date-2" data-type="date">
              </div>
            </div>


            <div *ngIf="currentInquiry && currentInquiry.service != inquiryServiceType.MOVE">
              <label for="Service-w-hlen" class="field-label box edit big _20px-top">Umzugsreinigung
                (optional)</label>
              <p class="bold">Reinigungsdatum</p>
              <p class="paragraph-8">Das Datum kann später angepasst werden.</p>
              <div class="udesly-input-wrapper-2">

                <input class="field-round kalender w-input" maxlength="256" formControlName="cleaning_date"
                  name="date-2" data-name="Date 2" placeholder="" type="date" id="date-2" data-type="date">
              </div>
            </div>

            <div *ngIf="currentInquiry && currentInquiry.service != inquiryServiceType.MOVE">
              <p class="bold">Abgabetermin</p>
              <p class="paragraph-8">Das Datum kann später angepasst werden.</p>
              <div class="udesly-input-wrapper-2">

                <input class="field-round kalender w-input" maxlength="256" formControlName="handover_date"
                  name="date-2" data-name="Date 2" placeholder="" type="date" id="date-2" data-type="date">
              </div>
            </div>

            <div class="kontakt">
              <label for="Service-w-hlen"
                class="field-label box edit big _20px-top">Kontaktangaben</label>
              <div class="herr-frau">

                <label class="w-checkbox">
                  <input type="radio" id="checkbox" data-name="Checkbox" class="w-checkbox-input"
                    formControlName="gender" value="man">
                  <span class="w-form-label" for="checkbox">Mann</span>
                </label>
                <label class="w-checkbox _20px-left">

                  <input type="radio" id="checkbox-2" data-name="Checkbox 2" formControlName="gender" value="woman"
                    class="w-checkbox-input"><span class="w-form-label" for="checkbox-2">Frau</span>
                </label>
              </div>
              <input class="field-round w-input" maxlength="256" name="field-3" data-name="Field 3"
                formControlName="first_name" placeholder="Vorname" type="text" id="field-3">

              <input class="field-round w-input" maxlength="256" name="Nachname" data-name="Nachname"
                formControlName="last_name" placeholder="Nachname" type="text" id="Nachname">


              <input class="field-round w-input" maxlength="256" name="E-Mail" data-name="E-Mail" placeholder="E-Mail"
                type="email" id="E-Mail" formControlName="email">
              <input class="field-round w-input" maxlength="256" name="Telefon" data-name="Telefon"
                formControlName="phone" placeholder="Telefon" type="tel" id="Telefon">
              <p>Dank Ihrer Telefonnummer können wir Sie bei Rückfragen bzgl. Ihrer Anfrage erreichen</p>
              <p>
                <a href="https://www.movline24.ch/datenschutz" target="_blank">Datenschutzbestimmung</a>
              </p>

              <label class="w-checkbox">
                <input type="checkbox" id="checkbox-3" name="checkbox-3" formControlName="accepted_terms"
                  data-name="Checkbox 3" class="w-checkbox-input">
                <span class="w-form-label" for="checkbox-3">
                  <a href="https://www.movline24.ch/agb" target="_blank">AGBs</a> akzeptieren
                </span>
              </label>

              <label class="w-checkbox">
                <input type="checkbox" id="checkbox-3" name="checkbox-3" formControlName="newsletter"
                  data-name="Checkbox 3" class="w-checkbox-input">
                <span class="w-form-label" for="checkbox-3">
                  Ich möchte den Newsletter abonnieren
                </span>
              </label>

            </div>

          </div>
          <input (click)="onSubmit()" type="submit" data-wait="bitte warten" class="submit-button w-button"
            value="WEITER" style="margin-bottom: 20px;"
            [disabled]="isLoading"
            >
          <a class="movline-back-button w-button" [routerLink]="['..']" style="text-align: center;">ZURÜCK</a>
        </form>
        <pre *ngIf="environment.envName == 'local_dev'">{{draftInquiryForm.value | json}}</pre>

      </div>
    </div>
  </div>
</body>
