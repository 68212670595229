import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { lastValueFrom } from 'rxjs';
import { IAdminDetailOrder, MovLineOrderStateType } from 'projects/webapp/app/interfaces/order.interface';
import { environment } from 'projects/webapp/app/environments/environment';
import { deepCopy } from 'projects/webapp/app/utils';
import { AuthService } from 'projects/webapp/app/services/auth.service';


@Component({
  selector: 'app-admin-order-details',
  templateUrl: './admin-order-details.component.html',
  styleUrls: ['./admin-order-details.component.scss']
})
export class AdminOrderDetailsComponent implements OnInit {

  public isLoading = false;
  item: IAdminDetailOrder | null = null
  orderUUID: string | null = null;

  environment = environment;
  movLineOrderStateType = MovLineOrderStateType;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    public authService: AuthService,
  ) { }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Details des Auftrages`);
    this.route.params.subscribe(params => {
      if (!!params && params['orderUUID']) {
        this.orderUUID = params['orderUUID'];
        this.refresh();
      }
    });
  }

  async refresh() {
    try {
      this.spinnerService.show();
      if (this.orderUUID) {
        this.item = await lastValueFrom(this.adminService.getOrder(this.orderUUID))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }

  async onClickDone() {
    if (confirm('Sind Sie sicher, dass dieser Auftrag abgeschlossen wurde?') && this.orderUUID) {
      await lastValueFrom(this.adminService.markOrderAsDone(this.orderUUID))
    }
  }

  async onClickArchive() {
    if (confirm('Sind Sie sicher, dass der Auftrag archiviert werden soll?') && this.orderUUID) {
      const data = deepCopy(this.item)
      data.status = MovLineOrderStateType.ARCHIVED
      this.item = await lastValueFrom(this.adminService.updateOrder(this.orderUUID, data))
    }
  }

  async onClickDelete() {
    if (confirm('Sind Sie sicher, dass der Auftrag gelöscht werden soll?') && this.orderUUID) {
      const data = deepCopy(this.item)
      data.status = MovLineOrderStateType.DELETED
      this.item = await lastValueFrom(this.adminService.updateOrder(this.orderUUID, data))
    }
  }
}
