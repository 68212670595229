import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { outputErrors } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-inquiry-step6',
  templateUrl: './step6.component.html',
  styleUrls: ['./step6.component.scss']
})
export class InquiryStep6Component implements OnInit {
  isLoading = false;

  public draftInquiryForm = new FormGroup({
    from_address: new FormControl('', Validators.required),
    from_zip: new FormControl('', Validators.required),
    from_location: new FormControl('', Validators.required),

    to_address: new FormControl('', Validators.required),
    to_zip: new FormControl('', Validators.required),
    to_location: new FormControl('', Validators.required),

    moving_date: new FormControl(null),
    cleaning_date: new FormControl(null),
    handover_date: new FormControl(null),

    gender: new FormControl('man', Validators.required),

    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),

    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),

    newsletter: new FormControl(false, Validators.required),
    accepted_terms: new FormControl(false, Validators.requiredTrue),
    finished: new FormControl(true, Validators.required),
    service: new FormControl('1', Validators.required),
  });

  currentUUID: string = ''
  currentInquiry: any = null;

  environment = environment;
  inquiryServiceType = InquiryServiceType

  constructor(
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private publicInquiryService: PublicInquiryService) {
  }

  async ngOnInit() {
    this.draftInquiryForm.get('service')?.valueChanges.subscribe((value) => {
      const service = this.draftInquiryForm.get('service');
      if (service && service.value) {
        this.publicInquiryService.inquiryServiceType$.next(service.value as InquiryServiceType)
      }

      const from_zip = this.draftInquiryForm.get('from_zip');
      const from_location = this.draftInquiryForm.get('from_location');
      const to_zip = this.draftInquiryForm.get('to_zip');
      const to_location = this.draftInquiryForm.get('to_location');
      const to_address = this.draftInquiryForm.get('to_address');
      if (service?.value == '3') {
        from_zip?.clearValidators()
        from_location?.clearValidators()
        to_zip?.clearValidators()
        to_location?.clearValidators()
        to_address?.clearValidators()
      } else {
        from_zip?.setValidators([Validators.required]);
        from_location?.setValidators([Validators.required]);
        to_zip?.setValidators([Validators.required]);
        to_location?.setValidators([Validators.required]);
        to_address?.setValidators([Validators.required]);
      }
      from_zip?.updateValueAndValidity()
      from_location?.updateValueAndValidity()
      to_zip?.updateValueAndValidity()
      to_location?.updateValueAndValidity()
      to_address?.updateValueAndValidity()
    });



    let currentUUID = localStorage.getItem('currentInquiryUUID');
    if (!currentUUID) {
      this.route.queryParams.subscribe(async (params) => {
        currentUUID = params['currentInquiryUUID']
        console.log(currentUUID)

        if (currentUUID) {
          this.currentUUID = currentUUID;
          const response = await lastValueFrom(this.publicInquiryService.getEnquiry(this.currentUUID))
          this.draftInquiryForm.patchValue(response);
        } else {
          this.router.navigate(['/inquiry/1'])
        }
      });
    }
  }

  async onSubmit() {
    this.draftInquiryForm.markAllAsTouched()
    if (!this.draftInquiryForm.valid) {
      console.log(this.draftInquiryForm.errors)
      outputErrors(this.draftInquiryForm);
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.updateEnquiry(this.currentUUID, this.draftInquiryForm.value))
      this.router.navigate(['/inquiry/success']);
      localStorage.removeItem('currentInquiryUUID');
      localStorage.removeItem('currentInquiry');
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }

}
