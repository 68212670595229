import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IDetailedInquiry, InquiryServiceType } from '../../interfaces/inquiry.interface';
import { IDetailedOffer } from '../../interfaces/offer.interface';
import { calculateTotalPrice, hasMoveCleaning, hasMoveType } from '../../utils';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-offer-details-modal',
  templateUrl: './offer-details-modal.component.html',
  styleUrls: ['./offer-details-modal.component.scss']
})
export class OfferDetailsModalComponent implements OnInit {

  total_price = 0

  public form = new FormGroup({
    price_move: new FormControl(true),
    price_montage: new FormControl(true),
    price_cleaning: new FormControl(true),
    price_disposal: new FormControl(true),
    price_service_mount_lamps: new FormControl(true),
    price_service_floorliner: new FormControl(true),
    price_service_boxes: new FormControl(true),
    price_services_clothes_boxes: new FormControl(true),
    price_services_furniture_lift: new FormControl(true),

    terms_accepted: new FormControl(false, Validators.requiredTrue),
    payment_method: new FormControl('invoice', Validators.required),

  });

  constructor(
    public dialogRef: MatDialogRef<OfferDetailsModalComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {
      inquiry: IDetailedInquiry,
      offer: IDetailedOffer,
      withOptionToFinish: boolean
    },
  ) {

    const terms_accepted = this.form.get('terms_accepted');
    const payment_method = this.form.get('payment_method');

    if (this.data.withOptionToFinish) {
      terms_accepted?.setValidators([Validators.requiredTrue]);
      payment_method?.setValidators([Validators.required]);
    } else {
      terms_accepted?.clearValidators()
      payment_method?.clearValidators()
    }
    terms_accepted?.updateValueAndValidity()
    payment_method?.updateValueAndValidity()
  }

  async ngOnInit() {
    this.recalcTotalPrice(this.form.value)

    this.form.valueChanges.subscribe((value) => {
      console.log(`value changed:`);
      console.log(value);
      this.recalcTotalPrice(value)
    });
  }

  selectThisPaymentMethod(method: any) {
    this.form.patchValue({ payment_method: method })
  }

  recalcTotalPrice(value: any) {
    this.total_price = 0

    if (value.price_move) {
      this.total_price += parseInt(this.data.offer.price_move)
    }
    if (value.price_montage) {
      this.total_price += parseInt(this.data.offer.price_montage)
    }
    if (value.price_cleaning) {
      this.total_price += parseInt(this.data.offer.price_cleaning)
    }
    if (value.price_disposal) {
      this.total_price += parseInt(this.data.offer.price_disposal)
    }
    if (value.price_service_mount_lamps) {
      this.total_price += parseInt(this.data.offer.price_service_mount_lamps)
    }
    if (value.price_service_floorliner) {
      this.total_price += parseInt(this.data.offer.price_service_floorliner)
    }
    if (value.price_service_boxes) {
      this.total_price += parseInt(this.data.offer.price_service_boxes)
    }
    if (value.price_services_clothes_boxes) {
      this.total_price += parseInt(this.data.offer.price_services_clothes_boxes)
    }
    if (value.price_services_furniture_lift) {
      this.total_price += parseInt(this.data.offer.price_services_furniture_lift)
    }
    console.log(this.total_price)
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      console.log(this.form.controls.terms_accepted.errors)
      if (this.form.controls.terms_accepted.errors && this.form.controls.terms_accepted.errors['required']) {
        this.toastrService.warning('Bitte bestätigen Sie die AGBs.');
      } else {
        this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      }

      return
    }
    this.dialogRef.close(this.form.value)
  }

  calculateTotalPrice(inquiry: IDetailedInquiry, offer: IDetailedOffer) {
    return calculateTotalPrice(inquiry, offer)
  }

  hasMoveCleaning(arg0: InquiryServiceType): any {
    return hasMoveCleaning(arg0)
  }
  hasMoveType(arg0: InquiryServiceType): any {
    return hasMoveType(arg0)
  }

}
