import { Component, OnInit, Optional } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { versions } from 'projects/versions';
import { NUMBER_OF_ROOMS_MAPPING_LIST } from 'projects/webapp/app/constants';
import { environment } from 'projects/webapp/app/environments/environment';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { outputErrors } from 'projects/webapp/app/utils';
import { tap, debounceTime, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryV2Component implements OnInit {
  futureDatesOnly = (date: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date ? date >= today : false;
  };

  currentInquiry: any = null;

  submitText = 'ANFRAGE STARTEN'
  versions = versions;

  public draftInquiryForm = new FormGroup({
    uuid: new FormControl(''),

    moving_date: new FormControl(null),
    cleaning_date: new FormControl(null),
    handover_date: new FormControl(null),

    from_zip: new FormControl('', Validators.required),
    from_location: new FormControl('', Validators.required),
    from_address: new FormControl('', Validators.required),

    to_zip: new FormControl('', Validators.required),
    to_location: new FormControl('', Validators.required),
    to_address: new FormControl('', Validators.required),

    service: new FormControl('1', Validators.required),

    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),

    gender: new FormControl('man', Validators.required),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),

    from_size_rooms: new FormControl("1", Validators.required),

    tenant: new FormControl(null),
    comment: new FormControl(null),
    finished: new FormControl(true, Validators.required),
  });

  selectors = {
    number_of_rooms: {
      fieldName: 'Zimmer',
      options: NUMBER_OF_ROOMS_MAPPING_LIST
    }
  }

  cachedKey = 'currentInquiry'
  isLoading = false;

  environment = environment;
  inquiryServiceType = InquiryServiceType

  source: string | null = null
  medium: string | null = null
  campaign: string | null = null

  constructor(
    private spinnerService: NgxSpinnerService,
    private publicInquiryService: PublicInquiryService,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    @Optional() private angularFireAnalytics: AngularFireAnalytics,
  ) {
  }

  ngOnInit(): void {



    this.draftInquiryForm.get('service')?.valueChanges.subscribe((value) => {
      const service = this.draftInquiryForm.get('service');

      if (service && service.value) {
        this.publicInquiryService.inquiryServiceType$.next(service.value as InquiryServiceType)
      }

      const from_address = this.draftInquiryForm.get('from_address');
      const from_zip = this.draftInquiryForm.get('from_zip');
      const from_location = this.draftInquiryForm.get('from_location');

      const to_address = this.draftInquiryForm.get('to_address');
      const to_zip = this.draftInquiryForm.get('to_zip');
      const to_location = this.draftInquiryForm.get('to_location');
      if (service?.value == InquiryServiceType.CLEAN) {
        // from_zip?.clearValidators()
        // from_location?.clearValidators()
        to_address?.clearValidators()
        to_zip?.clearValidators()
        to_location?.clearValidators()
      } else {
        from_address?.setValidators([Validators.required]);
        from_zip?.setValidators([Validators.required]);
        from_location?.setValidators([Validators.required]);

        to_address?.setValidators([Validators.required]);
        to_zip?.setValidators([Validators.required]);
        to_location?.setValidators([Validators.required]);
      }
      from_address?.updateValueAndValidity()
      from_zip?.updateValueAndValidity()
      from_location?.updateValueAndValidity()

      to_address?.updateValueAndValidity()
      to_zip?.updateValueAndValidity()
      to_location?.updateValueAndValidity()
    });

    this.draftInquiryForm.valueChanges.pipe(
      tap(() => (this.isLoading = true)),
      debounceTime(300)
    ).subscribe(val => {
      console.log(val)
      localStorage.setItem(this.cachedKey, JSON.stringify(val));
    });

    const val = localStorage.getItem(this.cachedKey);
    if (!!val) {
      console.log('had a current inquiry in the session')
      this.draftInquiryForm.patchValue(JSON.parse(val))
    }

    this.route.queryParams.subscribe(params => {
      try {
        this.source = params['utm_source'];
        this.medium = params['utm_medium'];
        this.campaign = params['utm_campaign'];
        console.log(`Source: ${this.source}, Medium: ${this.medium}, Campaign: ${this.campaign}`);
      } catch (error) {
        console.warn(error)
      }

      const tenant = params['tenant']
      if (!!tenant) {
        console.log(`using a tenant id ${tenant}`)
        this.draftInquiryForm.patchValue({
          tenant: tenant
        });
      }

      const service = params['service']
      if (!!service) {
        if (service == 'clean') {
          console.log('going to only for clean')
          this.draftInquiryForm.patchValue({
            service: InquiryServiceType.CLEAN
          });
        }
        if (service == 'move') {
          console.log('going to only for moving')
          this.draftInquiryForm.patchValue({
            service: InquiryServiceType.MOVE
          });
        }
      }
    });

    const currentUUID = localStorage.getItem('currentInquiryUUID');
    if (currentUUID) {
      this.draftInquiryForm.patchValue({ uuid: currentUUID });
      this.submitText = 'ANFRAGE BEARBEITEN'
    }
  }

  async onSubmit() {
    console.log('going to submit')
    this.draftInquiryForm.markAllAsTouched()
    if (!this.draftInquiryForm.valid) {
      console.log(this.draftInquiryForm.errors)
      outputErrors(this.draftInquiryForm)
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show();

      const currentUUID = localStorage.getItem('currentInquiryUUID');
      if (currentUUID) {
        this.draftInquiryForm.patchValue({ uuid: currentUUID })
      }

      const moving_date = this.draftInquiryForm.value.moving_date;
      if (moving_date) {
        try {
          const moving_date_str = this.formatDate(moving_date);
          this.draftInquiryForm.patchValue({ moving_date: moving_date_str as any })
        } catch (error) {
          console.error(error)
        }
      }

      const cleaning_date = this.draftInquiryForm.value.cleaning_date;
      if (cleaning_date) {
        try {
          const cleaning_date_str = this.formatDate(cleaning_date);
          this.draftInquiryForm.patchValue({ cleaning_date: cleaning_date_str as any })
        } catch (error) {
          console.error(error)
        }
      }

      const handover_date = this.draftInquiryForm.value.handover_date;
      if (handover_date) {
        try {
          const handover_date_str = this.formatDate(handover_date);
          this.draftInquiryForm.patchValue({ handover_date: handover_date_str as any })
        } catch (error) {
          console.error(error)
        }
      }

      const response = await lastValueFrom(this.publicInquiryService.createEnquiry(this.draftInquiryForm.value))
      console.log(response)

      try {
        this.angularFireAnalytics.logEvent('conversion', {
          // send_to: 'AW-123456789/abcdEFGhij12345', // Replace with your Conversion ID and Label
          source: this.source || 'organic',
          medium: this.medium || 'none',
          campaign: this.campaign || 'none',
        });
      } catch (error) {
        console.error(error)
      }

      // this.router.navigate(['/inquiry/next-steps']);
      // this.router.navigate(['/inquiry/next-steps'], params= {});
      this.router.navigate(['/inquiry/next-steps'], {
        queryParams: { currentInquiryUUID: response.uuid },
      });
      localStorage.removeItem('currentInquiryUUID');
      localStorage.removeItem('currentInquiry');

    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }


  private formatDate(date: Date): string {
    if (typeof (date) == "string") {
      date = new Date(date)
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
