<ngx-spinner [fullScreen]="false">Loading...</ngx-spinner>

<section class="header">
  <div class="div-block-4"><img src="/assets/logos/movline-logo-white.png" loading="lazy" width="207" alt=""></div>
</section>
<div class="step-1" *ngIf="item">

  <div *ngIf="!showSuccessMessage" style="padding: 15px;">

    <div *ngIf="item.tenant else notenant">
      <h1 class="heading">Das Angebot der Movline-Partner Firma</h1>
      <p class="paragraph-9">Um die Offerte zu akzeptieren, wählen Sie bitte die Details mit den Boxen, anschliessend
        mit bitte unten bestätigen.</p>
    </div>

    <ng-template #notenant>
      <div>
        <h1 class="heading">Wählen Sie das beste Angebot aus</h1>
        <!-- <p class="paragraph-9">Um die Offerte auszuwählen, drücken Sie einfach auf die Box.</p> -->
      </div>
    </ng-template>

    <div class="row">

      <div class="col-sm-12 col-md-5">
        <!-- <mat-card class="mt-3" *ngIf="form.get('offer')?.value == '' "> -->
        <mat-card class="mt-3">
          <mat-card-content>
            <div>
              <div>
                <h4 class="">Kostenlose Besichtigung anfordern um weitere details zu klären</h4>
                <div style="display: flex; justify-content: center;">
                  <img src="/assets/places-visit_4052301.png" width="50%" />
                </div>
                <p>Sie können eine Besichtigung von offerierenden Firmen anfordern. Sie setzten sich mit Ihnen in
                  Verbindung, um alle Details mit Ihnen durchzugehen. Dieser Service ist für Sie
                  kostenlos.</p>

                <button (click)="onClickRequestVisit()" class="submit-button w-button w-100 mt-1" [disabled]="isLoading"
                  [ngClass]="{'movline-disabled': !canRequestVisit}">Besichtigung
                  anfragen</button>
              </div>

            </div>

          </mat-card-content>
        </mat-card>

        <mat-card class="mt-3">
          <mat-card-header>
            <mat-card-title>Anfragetyp: {{item.service | serviceTypePipe}}</mat-card-title>
            <mat-card-subtitle>Anfragenummer: {{item.id}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>

            <div *ngIf="hasMoveCleaning(item.service)" class="mt-3">
              <h4>Reinigungsservice</h4>
              <h5>Datum</h5>
              <p>{{item.cleaning_date | date: 'yyyy-MM-dd'}}</p>
              <hr>
            </div>

            <div *ngIf="hasMoveType(item.service)" class="mt-3">
              <h4>Umzugsservice</h4>
              <h5>Datum</h5>
              <p>{{item.moving_date | date: 'yyyy-MM-dd'}}</p>
              <h5>Umzug von</h5>
              <p>{{item.from_address}}, {{item.from_zip}} {{item.from_location}}</p>
              <h5>Umzug nach</h5>
              <p>{{item.to_address}}, {{item.to_zip}} {{item.to_location}}</p>
              <hr>
            </div>


          </mat-card-content>
        </mat-card>


      </div>
      <!-- <div class="col-sm-12 col-md-1 movline-box">

      </div> -->

      <div class="col-sm-12 col-md-7 movline-box">

        <div *ngFor="let offer of offers">
          <mat-card class="mt-3" [ngClass]="{'card-selected mat-elevation-z4': form.get('offer')?.value == offer.uuid}"
            *ngIf="form.get('offer')?.value == '' || form.get('offer')?.value == offer.uuid ">
            <span class="selection">
              <mat-icon *ngIf="form.get('offer')?.value == offer.uuid ">check_circle</mat-icon>
            </span>

            <mat-card-header>
              <mat-card-title>{{offer.organization_object.name}}
                <small *ngIf="!!offer.organization_object.ratings" style="padding-left: 20px;">
                  <!-- <mat-icon>stars</mat-icon> -->
                  ⭐
                  {{offer.organization_object.ratings.average_rating}}/5
                  ({{offer.organization_object.ratings.count_rating}} <a [routerLink]="[]" (click)="onClickSeeRating(offer)"><strong>Bewertungen</strong></a>)
                </small>
              </mat-card-title>
              <!-- <mat-card-subtitle>

              </mat-card-subtitle> -->

              <mat-card-subtitle>
                Bereits {{ offer.organization_object.orders.count_orders }} mal gebucht
              </mat-card-subtitle>

              <mat-card-subtitle>
                Registriert seit {{offer.organization_object.created_at | date: 'yyyy-MM-dd' }}
              </mat-card-subtitle>

            </mat-card-header>
            <mat-card-content>
              <div>
                <button (click)="onClickRequestVisitForOrg(offer.organization_object.uuid)"
                  class="submit-button w-button w-100 mt-1 secondary-button smaller-buttons" [disabled]="isLoading"
                  [ngClass]="{'movline-disabled': !canRequestVisit}">Besichtigung
                  anfragen</button>

                <button (click)="onClickDetailsOfOffer(offer)"
                  class="submit-button w-button w-100 mt-2 secondary-button smaller-buttons">Details der
                  Offerte</button>

                <button (click)="onClickAcceptOffer(offer)" class="submit-button w-button w-100 mt-2" style="font-weight: 900;">
                    Offerte annehmen
                </button>

              </div>

            </mat-card-content>
          </mat-card>
        </div>

        <button (click)="onClickResetOffer()" *ngIf="form.get('offer')?.value != '' "
          class="submit-button w-button w-100 mt-1">Andere Offerten
          einblenden</button>
      </div>

    </div>

    <div>

      <br>
      <!-- <br><br> -->
      <!-- <p class="paragraph-9">Wählen Sie bitte die gewünschte Zahlungsart. Drücken Sie bitte auf die entsprechende Box.
      </p>
      <div style="display: flex; justify-content: space-around;">
        <div class="payment-method-wrapper" style="cursor: pointer;" (click)="selectThisPaymentMethod('invoice')"
          [ngClass]="{ 'selected': form.get('payment_method')?.value =='invoice' }">
          <h4>Rechnung</h4>
          <img src="/assets/invoice.jpeg">
          <input style="display: none;" type="radio" formControlName="payment_method" value="invoice">
        </div>
        <div class="payment-method-wrapper" style="cursor: pointer;"
          (click)="selectThisPaymentMethod('cash_at_location')"
          [ngClass]="{ 'selected': form.get('payment_method')?.value =='cash_at_location' }">
          <h4>Bar</h4>
          <img src="/assets/cash.jpeg">
          <input style="display: none;" type="radio" formControlName="payment_method" value="cash_at_location">
        </div>
      </div> -->

      <!-- <br> -->
      <!-- <form [formGroup]="form">
        <div style="display: flex; justify-content: space-around;" class="mb-5">
          <label class="w-checkbox">
            <input type="checkbox" id="checkbox-3" name="checkbox-3" formControlName="terms_accepted"
              data-name="Checkbox 3" class="w-checkbox-input movline-checkbox">
            <span class="w-form-label" for="checkbox-3">
              Ich akzeptiere die <a href="https://www.movline24.ch/agb" target="_blank">AGBs</a>
            </span>
          </label>
        </div>
      </form> -->
      <!-- <input type="submit" (click)="onSubmit()" data-wait="Please wait..." class="submit-button w-button"
        value="Angebot annehmen"> -->
    </div>

  </div>

  <div *ngIf="showSuccessMessage">
    <div style="width: 100%;" class="alert alert-success">Der Auftrag wurde übermittelt, Sie hören bald von uns.</div>

    <a style="width: 100%;" href="https://movline24.ch/" class="submit-button w-button mt-2">Zur Home Page</a>

    <a *ngIf="resultOrder" style="width: 100%;" href="{{environment.uiDomain}}/order-details?tk={{resultOrder.uuid}}"
      class="submit-button w-button mt-2">Auftrag sehen</a>
  </div>

</div>

<pre *ngIf="environment.envName == 'local_dev'">{{form.value | json}}</pre>
<pre *ngIf="environment.envName == 'local_dev'">{{item | json}}</pre>
<pre *ngIf="environment.envName == 'local_dev'">{{offers | json}}</pre>
