import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';


export interface ISelectorOption {
  value: string | number;
  display: string;
}

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent implements OnInit, AfterViewInit {

  @Input() fieldName = ''
  @Input() optionsList: ISelectorOption[] = []
  @Input() control: any;

  @ViewChild('selector') selector: ElementRef | undefined
  @ViewChild('prev') prev: ElementRef | undefined
  @ViewChild('next') next: ElementRef | undefined

  constructor(
  ) {
  }

  ngOnInit(): void {
    console.log(this.control);
  }

  ngAfterViewInit() {
    if (this.selector)
      console.log(this.selector.nativeElement.selectedIndex);
    if (this.control && this.control.value && this.selector) {
      // the const begin with value 1 but option array start with option index 0
      this.selector.nativeElement.selectedIndex = this.control.value;
    }
  }

  changePropertyValue(property: string, operation: 'increase' | 'decrease') {
    console.log('this.selector')
    console.log(this.selector)
    if (this.selector) {
      if (operation == 'increase') {
        console.log(`increase: ${property}`)
        if (this.selector.nativeElement.selectedIndex < this.selector.nativeElement.length - 1) {
          this.selector.nativeElement.selectedIndex++;
        }
      } else {
        console.log(`decrease: ${property}`)
        if (this.selector.nativeElement.selectedIndex > 0) {
          this.selector.nativeElement.selectedIndex--;
        }
      }
      this.control.setValue(this.selector.nativeElement.value);

      if (this.prev && this.next) {
        this.prev.nativeElement.style.opacity = this.selector.nativeElement.selectedIndex === 0 ? '0.5' : '1';
        this.next.nativeElement.style.opacity = this.selector.nativeElement.selectedIndex === this.selector.nativeElement.options.length - 1 ? '0.5' : '1';
      }

    }
  }

}
