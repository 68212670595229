import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { IAdminDetailedOffer } from 'projects/webapp/app/interfaces/offer.interface';
import { environment } from 'projects/webapp/app/environments/environment';


@Component({
  selector: 'app-admin-offers-details',
  templateUrl: './admin-offers-details.component.html',
  styleUrls: ['./admin-offers-details.component.scss']
})
export class AdminOffersDetailsComponent implements OnInit {

  public isLoading = false;

  environment = environment;
  offerUUID = '';
  item: IAdminDetailedOffer | null = null;

  constructor(
    private title: Title,
    private router: Router,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
  }

  async ngOnInit() {
    this.title.setTitle(`Movline24: Details der Offerte`);
    this.route.params.subscribe(params => {
      if (!!params && params['offerUUID']) {
        this.offerUUID = params['offerUUID'];
        this.refresh();
      }
    });
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show()
      this.item = await lastValueFrom(this.adminService.getOffer(this.offerUUID))
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  applyFilter() {
    this.refresh()
  }

  onClickDetails(uuid: string) {
    this.router.navigate(['/main/admin/offers/' + uuid])
  }

}
