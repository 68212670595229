import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/webapp/app/environments/environment';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { lastValueFrom } from 'rxjs';
import { InquiryServiceType } from 'projects/webapp/app/interfaces/inquiry.interface';
import { NUMBER_OF_ROOMS_MAPPING_LIST, FROM_SIZE_SQUARE_METERS, SIZE_FLOORS } from 'projects/webapp/app/constants';
import { outputErrors } from 'projects/webapp/app/utils';

@Component({
  selector: 'app-inquiry-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss']
})
export class InquiryStep4Component implements OnInit {

  public draftInquiryForm = new FormGroup({
    to_type: new FormControl('', Validators.required),
    to_size_rooms: new FormControl("3", Validators.required),
    to_size_square_meters: new FormControl("2", Validators.required),
    to_size_floors: new FormControl("1", Validators.required),
    to_type_of_elevator: new FormControl('1', Validators.required),

    to_has_more_than_5_meters: new FormControl(false),
    to_way_from_parking_meters: new FormControl("10", Validators.required),

    to_has_stair_steps: new FormControl(false, Validators.required),
    to_number_of_stair_steps: new FormControl("20", Validators.required),

    number_of_persons: new FormControl("1", Validators.required),
    number_of_cartons: new FormControl("1", Validators.required),

    to_other_difficulties: new FormControl(false, Validators.required),
    to_other_difficulties_description: new FormControl("",),

    service_trash_per_cubic: new FormControl(false, Validators.required),
    service_mount_lamps: new FormControl(false, Validators.required),
    service_number_of_lamps: new FormControl(0),

    service_floorliner: new FormControl(false, Validators.required),
    service_boxes: new FormControl(false, Validators.required),
    services_clothes_boxes: new FormControl(false, Validators.required),
    service_number_of_clothes_boxes: new FormControl(0),

    services_furniture_lift: new FormControl(false, Validators.required),
    services_assembling: new FormControl(false, Validators.required),

    service: new FormControl('1', Validators.required),

  });

  currentUUID: string = ''
  environment = environment;

  selectors = {
    number_of_rooms: {
      fieldName: 'Zimmer',
      options: NUMBER_OF_ROOMS_MAPPING_LIST
    },
    to_size_square_meters: {
      fieldName: 'Wohnfläche',
      options: FROM_SIZE_SQUARE_METERS
    },
    to_size_floors: {
      fieldName: 'Stockwerke',
      options: SIZE_FLOORS
    },
    // number_of_floors: {
    //   fieldName: 'Stockwerk',
    //   options: [
    //     { value: "1", display: "1" },
    //     { value: "2", display: "2" },
    //     { value: "3", display: "3" },
    //     { value: "4", display: "4" },
    //     { value: "5", display: "5" },
    //     { value: "6", display: "6" },
    //     { value: "7", display: "7" },
    //     { value: "8", display: "8" },
    //     { value: "9", display: "9" },
    //     { value: "10", display: "10" },
    //   ]
    // },
    to_way_from_parking_meters: {
      fieldName: 'Distanz in Meter ca.',
      options: [
        { value: "0", display: "k.A." },
        { value: "10", display: "10" },
        { value: "20", display: "20" },
        { value: "30", display: "30" },
        { value: "40", display: "40" },
        { value: "50", display: "50" },
        { value: "60", display: "60" },
        { value: "70", display: "70" },
        { value: "80", display: "80" },
        { value: "90", display: "90" },
        { value: "100", display: "+100" },
      ]
    },
    to_number_of_stair_steps: {
      fieldName: 'Anzahl Stufen ca.',
      options: [
        { value: "0", display: "k.A." },
        { value: "10", display: "10" },
        { value: "20", display: "20" },
        { value: "30", display: "30" },
        { value: "40", display: "40" },
        { value: "50", display: "50" },
        { value: "60", display: "60" },
        { value: "70", display: "70" },
        { value: "80", display: "80" },
        { value: "90", display: "90" },
        { value: "100", display: "+100" },
      ]
    },
    number_of_persons: {
      fieldName: 'Personen',
      options: [
        { value: "0", display: "k.A." },
        { value: "1", display: "1" },
        { value: "2", display: "2" },
        { value: "3", display: "3" },
        { value: "4", display: "4" },
        { value: "5", display: "5" },
        { value: "6", display: "6" },
        { value: "7", display: "7" },
        { value: "8", display: "8" },
        { value: "9", display: "9" },
        { value: "10", display: "10" },
      ]
    },
    number_of_cartons: {
      fieldName: 'Umzugskartons zu transportieren',
      options: [
        { value: "0", display: "k.A." },
        { value: "10", display: "10" },
        { value: "20", display: "20" },
        { value: "30", display: "30" },
        { value: "40", display: "40" },
        { value: "50", display: "50" },
        { value: "60", display: "60" },
        { value: "70", display: "70" },
        { value: "80", display: "80" },
        { value: "90", display: "90" },
        { value: "100", display: "100" },
        { value: "110", display: "110" },
        { value: "120", display: "120" },
        { value: "130", display: "130" },
        { value: "140", display: "140" },
        { value: "150", display: "150" },
      ]
    },
  }

  constructor(
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,

    private publicInquiryService: PublicInquiryService
  ) {
  }

  async ngOnInit() {
    this.draftInquiryForm.get('service')?.valueChanges.subscribe((value) => {
      const service = this.draftInquiryForm.get('service');
      if (service && service.value) {
        this.publicInquiryService.inquiryServiceType$.next(service.value as InquiryServiceType)
      }
    });

    let currentUUID = localStorage.getItem('currentInquiryUUID');
    if (!currentUUID) {
      this.route.queryParams.subscribe(async (params) => {
        currentUUID = params['currentInquiryUUID']
        console.log(currentUUID)

        if (currentUUID) {
          this.currentUUID = currentUUID;
          const response = await lastValueFrom(this.publicInquiryService.getEnquiry(this.currentUUID))
          this.draftInquiryForm.patchValue(response);
        } else {
          this.router.navigate(['/inquiry/1'])
        }
      });
    }
  }

  async onSubmit() {
    this.draftInquiryForm.markAllAsTouched()
    if (!this.draftInquiryForm.valid) {
      outputErrors(this.draftInquiryForm)
      this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      return
    }

    try {
      this.spinnerService.show()
      const response = await lastValueFrom(this.publicInquiryService.updateEnquiry(this.currentUUID, this.draftInquiryForm.value))
      this.router.navigate(['/inquiry/5'], { queryParamsHandling: 'merge' })
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.spinnerService.hide()
    }
  }

}
