<!-- <h1 mat-dialog-title>
  <span>Details der Firma</span>
</h1> -->
<div mat-dialog-content>

  <!-- <ngx-spinner [name]="'company-ratings'" [fullScreen]="false">Loading...</ngx-spinner> -->

  <!-- <section class="header">
    <div class="div-block-4"><img src="/assets/logos/movline-logo-white.png" loading="lazy" width="207" alt=""></div>
  </section> -->

  <!-- <button (click)="onClickRequestVisit()" class="submit-button w-button w-100 mt-4" [disabled]="isLoading">Besichtigung
    anfragen</button> -->
  <div class="step-1">

    <div style="padding: 15px;" *ngIf="org && items">

      <h1 class="heading">Bewertungen von der Firma <i>{{org.name}}</i></h1>
      <!-- <p class="paragraph-9">Um die Offerte auszuwählen, drücken Sie einfach auf die Box.</p> -->
      <div *ngIf="!isLoading && items.length == 0 ">
        <div class="alert alert-info mt-5">Keine Bewertungen</div>
        <img src="/assets/empty.jpg" style="width: 100%; padding: 30px;">
      </div>

      <div class="rating-stars" style="display: flex; justify-content: center; margin-bottom: 40px; margin-top: 40px;">
        <div class="Stars" style="--rating: {{average_rating}};" aria-label="Rating of this product is 2.3 out of 5.">
        </div>
      </div>

      <div *ngFor="let item of items">
        <hr>
        <!-- <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b252affa75b65c46078e94c2c2f3459c99405da961cdb4182c5f47db6d64bc20?"
        class="img-3" /> -->

        <!-- <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1b65bc4085f6923653af8dc3ad86f2b9f2977019b3a5a8dddd95b713f89846be?"
        class="img-4" /> -->
        <div class="div-8">
          <div class="div-9">
            <span style="color: rgba(154, 153, 155, 1)">Datum: {{item.updated_at | date: 'yyyy-MM-dd'}}</span>
            <br />
          </div>
          <div class="div-10">
            <div class="div-11">
              <!-- {{item.rating}}/5 -->
              <div class="rating-stars">
                <div class="Stars" style="--rating: {{item.rating}};"
                  aria-label="Rating of this product is 2.3 out of 5.">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="div-6">{{item.author}}</div>
        <div class="div-7">
          {{item.text}}
        </div>
        <!-- <pre *ngIf="environment.envName == 'local_dev'">{{items | json}}</pre> -->
        <!-- <pre *ngIf="environment.envName == 'local_dev'">{{org | json}}</pre> -->

      </div>

    </div>
  </div>


</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">Schliessen</button>
  <!-- <button mat-button color="primary" (click)="save()"  cdkFocusInitial>Offerte auswählen</button> -->
</div>
