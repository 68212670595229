<body class="body">
  <section class="header">
    <div class="div-block-4">
      <img src="/assets/logos/movline-logo-white.png" loading="lazy" width="207" alt=""></div>
  </section>
  <div class="step-1">
    <h1 class="heading">Vielen Dank für Ihre Anfrage</h1>
    <p class="paragraph">Wir suchen für Sie die besten Partnerfirmen</p>
    <p class="paragraph-9">Ihre Anfrage wird nun von unserem Team geprüft und auf der Movline Plattform publiziert. Sie
      erhalten danach per Mail 5 Offerten, welche Sie vergleichen können und online bestätigen können.  Bei Fragen
      wenden Sie sich an unser <a href="https://www.movline24.ch/kontakt" target="_blank"
        class="link-2">Support Team</a>. </p>
    <a href="https://www.movline24.ch/" class="submit-button w-button">zurück zur Webseite</a>
  </div>
</body>
