import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CompanyDetailsModalComponent } from 'projects/webapp/app/components/company-details-modal/company-details-modal.component';
import { OfferDetailsModalComponent } from 'projects/webapp/app/components/offer-details-modal/offer-details-modal.component';
import { environment } from 'projects/webapp/app/environments/environment';
import { IDetailedInquiry, InquiryServiceType, InquiryStatus } from 'projects/webapp/app/interfaces/inquiry.interface';
import { IDetailedOffer } from 'projects/webapp/app/interfaces/offer.interface';
import { PublicInquiryService } from 'projects/webapp/app/services/public-inquiry.service';
import { calculateTotalPrice, hasMoveCleaning, hasMoveType, outputErrors } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-company-selection-modal',
  template: `
  <h1 mat-dialog-title>
  <span>Kostenlose Besichtigung anfragen</span>
  </h1>
  <div mat-dialog-content>
    <p>
      Lassen Sie Ihr Inventar bei einem Hausbesuch erfassen und lernen Sie Ihre Wunschfirma persönlich kennen. Die Firma wird Sie innerhalb von 24 Stunden kontaktieren, um einen Termin zu vereinbaren.
    </p>
        <form class="flexbox-row d-flex align-items-center  " [formGroup]="form">


                     <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Firma wählen</mat-label>
                      <mat-select formControlName="organization_uuid">
                        <mat-option [value]="option.company_uuid" *ngFor="let option of data.companies">
                          {{option.company_name}}
                        </mat-option>

                      </mat-select>
                    </mat-form-field>

                    <!-- <pre>{{data.companies | json}}</pre> -->
                    <!-- <pre>{{form.value | json}}</pre> -->
</form>
  </div>
  <div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">Schliessen</button>
  <button mat-button color="primary" (click)="save()" cdkFocusInitial>Speichern</button>
  </div>
  `
})
export class CompanySelectionModalComponent implements OnInit {
  public form = new FormGroup({
    organization_uuid: new FormControl('', Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<CompanySelectionModalComponent>,
    private toastrService: ToastrService,

    @Inject(MAT_DIALOG_DATA) public data: {
      companies: { company_name: string, company_uuid: string }[],
    },
  ) { }

  async ngOnInit() {

  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      this.toastrService.info('Bitte wählen sie eine Firma aus, falls Sie eine kostenlose Besichtigung wünschen')
      return
    }
    this.dialogRef.close(this.form.value)
  }
}







@Component({
  selector: 'app-choose-offer',
  templateUrl: './choose-offer.component.html',
  styleUrls: ['./choose-offer.component.scss']
})
export class ChooseOfferComponent implements OnInit {


  canRequestVisit = true;

  public form = new FormGroup({
    token: new FormControl('', Validators.required),
    offer: new FormControl('', Validators.required),
    terms_accepted: new FormControl(false, Validators.requiredTrue),
    payment_method: new FormControl('invoice', Validators.required),

    price_move: new FormControl(true),
    price_montage: new FormControl(true),
    price_cleaning: new FormControl(true),
    price_disposal: new FormControl(true),
    price_service_mount_lamps: new FormControl(true),
    price_service_floorliner: new FormControl(true),
    price_service_boxes: new FormControl(true),
    price_services_clothes_boxes: new FormControl(true),
    price_services_furniture_lift: new FormControl(true),

  });

  total_price = 0;

  isLoading = false;

  environment = environment;
  inquiryUUID: string | undefined = undefined;

  offers: IDetailedOffer[] = []
  item: IDetailedInquiry | undefined = undefined
  showSuccessMessage = false;
  resultOrder: any = null;
  inquiryServiceType = InquiryServiceType;

  constructor(
    private spinnerService: NgxSpinnerService,
    private publicInquiryService: PublicInquiryService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!!params['tk']) {
        this.form.patchValue({ token: params['tk'] });
        this.inquiryUUID = params['tk']
        this.refresh()
      }
    });

    this.form.valueChanges.subscribe((value) => {
      console.log(`value changed:`);
      console.log(value);

      this.total_price = 0

      this.offers.forEach(offer => {
        if (offer.uuid == value.offer) {

          if (value.price_move) {
            this.total_price += parseInt(offer.price_move)
          }
          if (value.price_montage) {
            this.total_price += parseInt(offer.price_montage)
          }
          if (value.price_cleaning) {
            this.total_price += parseInt(offer.price_cleaning)
          }
          if (value.price_disposal) {
            this.total_price += parseInt(offer.price_disposal)
          }
          if (value.price_service_mount_lamps) {
            this.total_price += parseInt(offer.price_service_mount_lamps)
          }
          if (value.price_service_floorliner) {
            this.total_price += parseInt(offer.price_service_floorliner)
          }
          if (value.price_service_boxes) {
            this.total_price += parseInt(offer.price_service_boxes)
          }
          if (value.price_services_clothes_boxes) {
            this.total_price += parseInt(offer.price_services_clothes_boxes)
          }
          if (value.price_services_furniture_lift) {
            this.total_price += parseInt(offer.price_services_furniture_lift)
          }
          console.log(this.total_price)
        }
      });
    });
  }

  calculateTotalPrice(inquiry: IDetailedInquiry, offer: IDetailedOffer) {
    return calculateTotalPrice(inquiry, offer)
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      if (this.inquiryUUID) {
        this.item = await lastValueFrom(this.publicInquiryService.getEnquiry(this.inquiryUUID))
        this.offers = await lastValueFrom(this.publicInquiryService.getOffer(this.inquiryUUID))
        this.checkIfAlreadyDone()
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;

    }
  }

  async onClickRequestVisit() {
    let companies: any = []
    this.offers.forEach(offer => {
      companies.push({
        company_name: offer.organization_object.name,
        company_uuid: offer.organization_object.uuid,
      })
    })

    this.dialog.open(CompanySelectionModalComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: {
        companies: companies,
      },
    }).afterClosed().subscribe(async (val) => {
      console.log(val);

      if (!!val && this.inquiryUUID) {
        try {
          this.isLoading = true;
          this.spinnerService.show()
          if (this.inquiryUUID) {
            const response = await lastValueFrom(this.publicInquiryService.requestVisit(this.inquiryUUID, val.organization_uuid))
            console.log(response)
          }
        } catch (error) {
          console.error(error)
        } finally {
          // TEMP
          // this.canRequestVisit = false;
          setTimeout(() => {
            this.spinnerService.hide()
            this.isLoading = false;
            this.toastrService.success('Eine kostenlose Besichtigung wurde angefragt! Wir melden uns bald bei Ihnen.')
          }, 1000);
        }
      }
    });
  }

  onClickAcceptOffer(offer: IDetailedOffer) {
    this.dialog.open(OfferDetailsModalComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: {
        offer,
        inquiry: this.item,
        withOptionToFinish: true
      },
    }).afterClosed().subscribe(async (val) => {
      console.log(val);
      if (!!val && this.inquiryUUID) {
        this.form.patchValue({ offer: offer.uuid });
        this.form.patchValue(val);
        await this.onSubmit()
      }
    })
  }

  async onClickRequestVisitForOrg(organization_uuid: string) {
    if (!this.canRequestVisit) {
      this.toastrService.success('Eine kostenlose Besichtigung wurde bereits angefragt! Wir melden uns bald bei Ihnen.')
      return;
    }
    try {
      this.isLoading = true;
      this.spinnerService.show()
      if (this.inquiryUUID) {
        const response = await lastValueFrom(this.publicInquiryService.requestVisit(this.inquiryUUID, organization_uuid))
        console.log(response)
      }
    } catch (error) {
      console.error(error)
    } finally {
      // TEMP
      // this.canRequestVisit = false;
      setTimeout(() => {
        this.spinnerService.hide()
        this.isLoading = false;
        this.toastrService.success('Eine kostenlose Besichtigung wurde angefragt! Wir melden uns bald bei Ihnen.')
      }, 1000);
    }
  }

  checkIfAlreadyDone() {
    if (!!this.item && this.item.status == InquiryStatus.ASSIGNED) {
      this.showSuccessMessage = true;
    }
  }

  async onSubmit() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      console.log(this.form.errors)
      outputErrors(this.form);

      if (this.form.controls.offer.errors && this.form.controls.offer.errors['required']) {
        this.toastrService.warning('Bitte wählen Sie eine Offerte aus');
      } else if (this.form.controls.terms_accepted.errors && this.form.controls.terms_accepted.errors['required']) {
        this.toastrService.warning('Bitte bestätigen Sie die AGBs.');
      } else {
        this.toastrService.warning('Bitte füllen Sie alle Pflichtfelder aus.')
      }

      return
    }

    try {
      this.isLoading = true;
      this.spinnerService.show()
      if (this.inquiryUUID) {
        this.resultOrder = await lastValueFrom(this.publicInquiryService.chooseOffer(this.inquiryUUID, this.form.value));
        this.toastrService.success('Der Auftrag wurde übermittelt, Sie hören bald von uns.')
        this.showSuccessMessage = true;
      }
    } catch (error) {
      console.error(error);
      this.toastrService.error('Ups, etwas bei Übermittlung hat nicht geklappt. Bitte versuchen Sie es später nochmals.')
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }

  selectThisOffer(offer: any) {
    if (offer.uuid != this.form.value.offer) {
      this.form.patchValue({ offer: offer.uuid });
      this.form.patchValue({ price_move: true })
      this.form.patchValue({ price_montage: true })
      this.form.patchValue({ price_cleaning: true })
      this.form.patchValue({ price_disposal: true })
      this.form.patchValue({ price_service_mount_lamps: true })
      this.form.patchValue({ price_service_floorliner: true })
      this.form.patchValue({ price_service_boxes: true })
      this.form.patchValue({ price_services_clothes_boxes: true })
      this.form.patchValue({ price_services_furniture_lift: true })
    } else {
      this.form.patchValue({ offer: '' });
    }
  }

  onClickResetOffer() {
    this.form.patchValue({ offer: '' });
  }

  selectThisPaymentMethod(method: any) {
    this.form.patchValue({ payment_method: method })
  }

  hasMoveType(arg0: InquiryServiceType): any {
    return hasMoveType(arg0)
  }

  hasMoveCleaning(arg0: InquiryServiceType): any {
    return hasMoveCleaning(arg0)
  }

  onClickDetailsOfOffer(offer: IDetailedOffer) {
    this.dialog.open(OfferDetailsModalComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: {
        offer,
        inquiry: this.item
      },
    }).afterClosed().subscribe(async (val) => {
      console.log(val);
      if (!!val && this.inquiryUUID) {
        this.form.patchValue({ offer: offer.uuid });
        this.form.patchValue(val);
      }
    })
  }

  onClickSeeRating(offer: IDetailedOffer) {
    this.dialog.open(CompanyDetailsModalComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: {
        companyUUID: offer.organization_object.uuid,
        inquiry: this.item,
        offer: offer
      },
    }).afterClosed().subscribe(async (val) => {
      console.log(val);

      if (!!val && this.inquiryUUID) {
        this.form.patchValue({ offer: offer.uuid });
        this.form.patchValue(val);
      }
    })
  }

}
