<div class="container" *ngIf="item">
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h1>
      <span *ngIf="item.tenant">
        Direkte
      </span>
      Anfrage #{{item.id}}
    </h1>

    <a mat-icon-button href="{{environment.apiDomain}}/admin/accounts/movlineinquiry/{{item.id}}/change/"
      target="_blank">
      <mat-icon>edit</mat-icon>
    </a>
  </div>

  <div class="alert alert-warning" role="alert" style="width: 100%" *ngIf="item.tenant">
      Direkte Anfrage an <a routerLink="/main/admin/organizations/{{item.tenant}}">Partner firma</a>
  </div>

</div>

<app-inquiry-details-card [item]="item"></app-inquiry-details-card>

<div class="container" *ngIf="item">
  <div *ngIf="item" style="border: solid 1px red; padding: 4px;" class="mt-5">
    <div class="alert alert-warning">ADMIN Area (nicht einsehbar für "normalen Firmen")</div>
    <div class="alert alert-info">Status: {{item.status | inquiryStatusPipe}}</div>

    <a *ngIf="item.status==inquiryStatus.WAITING_FOR_ACCEPTANCE"
      href="{{environment.uiDomain}}/choose-offer?tk={{item.uuid}}" target="_blank">Angebotsseite wie der Kunde das
      sieht</a>

    <h5>Actions</h5>
    <div class="co-buttons-list">
      <a mat-flat-button color="primary"
        href="{{environment.apiDomain}}/admin/accounts/movlineinquiry/{{item.id}}/change/" target="_blank">
        Bearbeiten
      </a>

      <a mat-flat-button color="primary" routerLink="/main/admin/inquiries/{{item.uuid}}/edit-inventory">
        Inventar Bearbeiten
      </a>

      <button mat-flat-button color="primary" (click)="refresh()">
        Aktualisieren
      </button>
      <button mat-flat-button color="primary" (click)="onAdminReleaseInquiry()"
        *ngIf="item?.status==inquiryStatus.OPEN">
        Angebotsrunde öffnen
      </button>
      <button mat-flat-button color="primary" (click)="onAdminReleaseToInquirer()"
        *ngIf="item?.status==inquiryStatus.OPEN_FOR_BIDS">
        Angebotsrunde abschliessen
      </button>
      <button mat-flat-button color="accent" (click)="onAdminDeleteInquiry()">
        Löschen
      </button>
    </div>
    <hr>

    <div *ngIf="item">
      <h5>Angebote</h5>
      <table *ngIf="!!item && item.offer_objects && item.offer_objects.length" style="width: 100%"
        class="table table-striped">

        <thead>
          <th class="vertical-header">Firma</th>
          <th class="vertical-header">Angebot</th>
          <th class="vertical-header">Umzugspreis</th>
          <th class="vertical-header">Ab und Aufbau</th>
          <th class="vertical-header">Reinigung</th>
          <th class="vertical-header">Entsorgung pro m3</th>
          <th class="vertical-header">Lampenmontage</th>
          <th class="vertical-header">Floorliner</th>
          <th class="vertical-header">Kisten einpacken</th>
          <th class="vertical-header">Kleiderboxenmiete</th>
          <th class="vertical-header">Möbellift</th>
          <th class="vertical-header"></th>
        </thead>

        <tbody *ngIf="item.offer_objects">
          <tr *ngFor="let offer of item.offer_objects">
            <td>{{offer.organization_object.name}}</td>
            <td>{{offer.updated_at | humanDatePipe}}</td>

            <td>{{offer.price_move | thousandsSeparator}}</td>
            <td>{{offer.price_montage | thousandsSeparator}}</td>
            <td>{{offer.price_cleaning | thousandsSeparator}}</td>
            <td>{{offer.price_disposal | thousandsSeparator}}</td>

            <td>{{offer.price_service_mount_lamps | thousandsSeparator}}</td>
            <td>{{offer.price_service_floorliner | thousandsSeparator}}</td>
            <td>{{offer.price_service_boxes | thousandsSeparator}}</td>
            <td>{{offer.price_services_clothes_boxes | thousandsSeparator}}</td>
            <td>{{offer.price_services_furniture_lift | thousandsSeparator}}</td>

            <td>
              <div class="co-buttons-list">
                <a mat-icon-button color="primary" cdkFocusInitial
                  href="{{environment.apiDomain}}/admin/accounts/movlineoffer/{{offer.id}}/change/" target="_blank">
                  <mat-icon>edit</mat-icon>
                </a>

                <button mat-icon-button color="accent" cdkFocusInitial (click)="onAdminDeleteOffer(offer.uuid)">
                  <mat-icon>restore_from_trash</mat-icon>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!!item && item.offer_objects && item.offer_objects.length == 0" style="width: 100%">
        Noch keine Gebote
      </div>
      <hr>

      <h5>Kontaktdetails</h5>
      <mat-card class="mt-3">
        <mat-card-content>
          <ul class="co-simple-list">
            <li>E-Mail: {{item.email}}</li>
            <li>Anrede: {{item.gender}}</li>
            <li>Vorname: {{item.first_name}}</li>
            <li>Nachname: {{item.last_name}}</li>
            <li>Telefon: {{item.phone || "-"}}</li>
          </ul>
        </mat-card-content>
      </mat-card>
      <hr>

      <mat-card class="mt-3">
        <mat-card-content>
          <comments-card-component [title]="'Meine Anfragekommentare als Admin (für mich)'" [adminMode]="true"
            [public]="false" [entityUUID]="this.item.uuid"></comments-card-component>
        </mat-card-content>
      </mat-card>

      <hr>
      <mat-card class="mt-3">
        <mat-card-content>
          <comments-card-component [title]="'Meine Anfragekommentare als Admin (für alle)'" [adminMode]="true"
            [public]="true" [entityUUID]="this.item.uuid"></comments-card-component>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

  <pre *ngIf="environment.envName == 'local_dev'">
    {{item | json}}
  </pre>


</div>
