import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IDetailedUser, UsersService } from 'projects/webapp/app/services/users.service';


export interface MenuItem {
  title: string;
  icon: string;
  link: string;
  color: string;
  expanded?: boolean;
  subMenu?: MenuItem[];
}

export type Menu = MenuItem[];


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  opened = true;

  myUser: IDetailedUser | null = null;
  @ViewChild('leftSideNav') public leftSideNav: any;
  menu: Menu = [
  ];

  menuAdmin: Menu = [
    {
      title: 'Alle Orgs',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/organizations',
    },
    {
      title: 'Alle Benutzer',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/users',
    },

    {
      title: 'alle Anfragen',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/inquiries',
    },
    {
      title: 'alle Offerten',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/offers',
    },
    {
      title: 'alle Aufträge',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/orders',
    },
    {
      title: 'alle Bewertungen',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/ratings',
    },
    {
      title: 'Activity',
      icon: 'add',
      color: '#888888',
      link: '/main/admin/activity',
    },
  ];

  constructor(
    public authService: AuthService,
    public usersService: UsersService,
    public organizationsService: OrganizationsService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
  ) {
    this.checkIfNeedsToCloseListsPanel(false);
    this.usersService.initService();
    this.organizationsService.initService();
    this.route.url.subscribe(val => {
      this.refreshMenuItems()
    });
  }

  async ngOnInit() {
    this.usersService.ownUser$.asObservable().subscribe(val => {
      this.myUser = val;
      this.refreshMenuItems()
    })
  }

  refreshMenuItems() {
    if (!!this.myUser) {
      let menu: any[] = [
        {
          title: 'Übersicht',
          icon: 'home',
          // icon: 'dashboard',
          link: '/main/dashboard',
          color: '#146160',
        },
      ]

      // menu.push(
      //   {
      //     title: 'Kunde erfassen',
      //     icon: 'place',
      //     link: '/main/client-editor/new',
      //     color: '#146160',
      //   },
      // )

      menu.push(
        {
          title: 'Neue Anfragen',
          icon: 'message',
          link: '/main/new-inquiries',
          color: '#146160',
        },
      )

      menu.push(
        {
          title: 'Meine Offerten',
          icon: 'assignment_ind',
          link: '/main/my-offers',
          color: '#146160',
        },
      )

      menu.push(
        {
          title: 'Meine Aufträge',
          icon: 'assignment_turned_in',
          link: '/main/my-orders',
          color: '#146160',
        },
      )

      // menu.push(
      //   {
      //     title: 'Zahlungsübersicht',
      //     icon: 'place',
      //     link: '/main/my-payments',
      //     color: '#146160',
      //   },
      // )

      menu.push(
        {
          title: 'Meine Bewertungen',
          icon: 'stars',
          link: '/main/my-ratings',
          color: '#146160',
        },
      )

      this.menu = menu;

    }
  }

  toggle(event: any): void {
    if (!event && this.opened) {
      this.opened = false;
    } else {
      this.opened = !this.opened;
    }
  }

  checkIfNeedsToCloseListsPanel(withOpen = false): void {
    setTimeout(() => {
      if (window.innerWidth < 701) {
        console.log('smaller than 700');
        // this.leftSideNav.close();
        this.opened = false;
      }
      if (withOpen) {
        this.opened = true;
        // this.leftSideNav.open();
      }
    }, 500);
  }


  menuItemClicked(menuItem: MenuItem) {
    console.log(menuItem)

    this.checkIfNeedsToCloseListsPanel(false);
  }


  cancelLoading() {
    this.spinnerService.hide()
  }

}
